import React from 'react'
import { withRouter } from "react-router-dom";
import Axios from '../../config/axios';
import RichTextExample from "./text-editor/RichTextExample"
import DisplayActivity from './DisplayActivity';

class ActivityTab extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            activityList: [],
            reload: false
        }

        this.getActivityByProject = this.getActivityByProject.bind(this);
    }

    componentDidMount = () => {
        this.getActivityByProject();
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.reload !== prevProps.reload) {
            this.getActivityByProject();
            this.setState({
                reload: !this.state.reload
            });
        }
    }

    getActivityByProject() {
        let postdata = {
            activity: {}
        };
        postdata.activity.projectsID = this.props.location.state.project.projectsModel.projects_Id;

        Axios.post(`/api/GetActivityByProject`, postdata
        ).then(response => {
            this.setState({
                activityList: response.data
            });
        }).catch(error => {
            console.log(error);
        });
    }

    render() {
        return (
            
            <div className="activityDiv">
                <RichTextExample onRequestUpdate={this.getActivityByProject} project={this.props.location.state.project}/>
                <DisplayActivity activityList={this.state.activityList} reload={this.state.reload}/>
                
            </div>
        )
    }
}

export default withRouter(ActivityTab);