import React from 'react'
import { withRouter } from "react-router-dom";
import Moment from 'moment';

import ReactHtmlParser from 'react-html-parser';


class DisplayActivity extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            activityList: [],
            reload: false
           
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.reload !== this.props.reload) {
            this.setState({
                reload: !this.state.reload
            });
        }
        
    }

    render() {
        var actX = this.props.activityList[0];
        var act0 = this.props.activityList[0];
        var displayDate = true;
        return (
            <div className="activityDiv">
                

                {this.props.activityList.map((act, idx) => {
                    if (actX === act0){
                        displayDate = true;
                        act0 = null;
                    }
                    else if (Moment.utc(actX.createdDate).format('L') === Moment.utc(act.createdDate).format('L')
                       ) {
                        displayDate = false;
                    }
                    else {
                        displayDate = true;
                        actX = act;
                    }

                    return (
                        <div key={idx}>
                            {displayDate && 
                                <h3>{Moment(act.createdDate).format('L')}</h3>
                            }
                        <div className="individualActivity">
                            <div className="inputName">{act.inputName}</div>
                            <em className="fontSize14">updated on {Moment(act.createdDate).format('L')} at {Moment(act.createdDate).format('LT')}</em>
                            <div className="activityText">{ReactHtmlParser(act.activity)}</div>
                            </div>
                        </div>
                    );

                })}
            </div>
        )
    }
}

export default withRouter(DisplayActivity);