import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import ActivityTab from './ActivityTab';
import FileInput from './files/FileInput';

class TabPanel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            projectID: props.projectID,
            reload: false
        }

        
    }

    refreshList = () => {
        this.setState({ reload: !this.state.reload });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.reload !== this.props.reload) {
            this.setState({
                reload: !this.state.reload
            });
        }
    }

    render() {
        return (
            <div className="">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item">

                        <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home"
                            aria-selected="true" onClick={this.refreshList}><i className="fas fa-inbox"></i> Activity</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile"
                            aria-selected="false"><i className="fas fa-file-upload"></i> Files</a>
                    </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                        {/*<TaskTable projectID={this.props.projectID} project={this.props.project}/>*/}
                        <ActivityTab reload={this.state.reload} users={this.props.users} />
    </div>
                    <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                        <FileInput projectID={this.props.projectID} reload={this.state.reload} refreshList={this.refreshList} project={this.props.project} />
    </div>
                </div>

            </div>
        );
    }
}

export default withRouter(TabPanel);