import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import logo from '../img/ac-logo-color.png';
import Axios from '../config/axios';
import { smallBox } from "../common/utils/functions";
import jwt from 'jwt-simple';

class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.sendEmail = this.sendEmail.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();
        var user = null;
        var myuserid = document.getElementById("userid");
        let postdata = {};
        postdata.email = myuserid.value;

        Axios.post(`/api/GetUserByEmail`, postdata
        ).then(response => {
            user = response.data;
            if (user === "") {
                smallBox({
                    title: "Notice",
                    content: "<i class='fa fa-clock-o'></i> <i>There is no user associated with this email.</i>",
                    color: "#C46A69",
                    iconSmall: "fa fa-times fa-2x fadeInRight animated",
                    timeout: 4000
                });
            }
            else {
                this.sendEmail(myuserid.value);
                myuserid.value = "";
                smallBox({
                    title: "Notice",
                    content: "<i class='fa fa-clock-o'></i> <i>An email to reset your password has been sent.</i>",
                    color: "#659265",
                    iconSmall: "fa fa-check fa-2x fadeInRight animated",
                    timeout: 4000
                });
                this.props.history.push({
                    pathname: '/'
                });
            }
        }).catch(error => {
            console.log(error);
        });

        //Axios.post(`/api/SendUserEmail`, postdata
        //).then(response => {
        //}).catch(error => {
        //    console.log(error);
        //});
    }

    sendEmail(username) {

        var date = new Date();
        var secret = 'mysecret';

        let postdata = {};
        postdata.email = username;
        postdata.password = jwt.encode(date, secret);
        Axios.post(`/api/SendUserEmail`, postdata
        ).then(response => {
        }).catch(error => {
            console.log(error);
        });
    }

    render() {
        return (
            <div className="login-container">
                <div className="full-height">
                    <div className="top-header bg-dark">Automated Project Manager - Forgot Password</div>
                    <div className="row h-75vh">
                        <div className="left-panel-login">
                            <img src={logo} className="width100" alt="Automated Concepts, LLC" />
                        </div>
                        <div className="divider" style={{ border: '1px solid rgb(224, 224, 224)', marginTop: '40px', marginBottom: '40px' }}></div>
                        <div className="right-panel-login col">
                            <form>
                                <div className="text-center right-contents">
                                    <div className="full-width left-align">
                                        <span>Enter your username to retrieve password</span>
                                    </div>
                                    <input id="userid" className="form-control mb-4" />
                                </div>
                                <div className="btnRightContainer">
                                    <button onClick={this.handleSubmit} className="btn" type="submit">Reset Password</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(ForgotPassword);