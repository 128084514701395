import React, { Component } from 'react';


export class Counter extends Component {
  static displayName = Counter.name;

  constructor (props) {
    super(props);
    this.state = { currentCount: 0 };
    this.incrementCounter = this.incrementCounter.bind(this);
  }

  incrementCounter () {
    this.setState({
      currentCount: this.state.currentCount + 1
    });
  }

  render () {
    return (
        <div>
            <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">

                    <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home"
                        aria-selected="true"><i class="fas fa-code"></i> Home</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile"
                        aria-selected="false"><i class="fas fa-baby"></i> Profile</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact"
                        aria-selected="false"><i class="fab fa-earlybirds"></i> Contact</a>
                </li>
            </ul>
            <div class="tab-content" id="myTabContent">

                <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                    <div class="Box">
                        <div class="Box-row Box-row--gray">
                            <nav class="">
                                <div class="align-right">
                                    <details class="dropdown details-reset details-overlay d-inline-block">
                                        <summary class="text-gray p-2 d-inline" aria-haspopup="true">
                                            Dropdown
                                <i class="fas fa-angle-down"></i>
                                        </summary>

                                        <ul class="dropdown-menu dropdown-menu-se">
                                            <div class="Box">
                                                <div class="Box-header">
                                                    Filter by X
                                    </div>
                                                <div class="Box-header">
                                                    <input class="form-control input-block" type="text" placeholder="Full-width input" aria-label="Full-width input" />
                                                </div>
                                                <ul>
                                                    <li class="Box-row">
                                                        Box row one
                                        </li>
                                                    <li class="Box-row">
                                                        Box row two
                                        </li>
                                                </ul>

                                            </div>
                                        </ul>
                                    </details>
                                    <details class="dropdown details-reset details-overlay d-inline-block">
                                        <summary class="text-gray p-2 d-inline" aria-haspopup="true">
                                            Dropdown
                                <i class="fas fa-angle-down"></i>
                                        </summary>

                                        <ul class="dropdown-menu dropdown-menu-se">
                                            <div class="Box">
                                                <div class="Box-header">
                                                    Filter by X
                                    </div>
                                                <div class="Box-header">
                                                    <input class="form-control input-block" type="text" placeholder="Full-width input" aria-label="Full-width input" />
                                                </div>
                                                <ul>
                                                    <li class="Box-row">
                                                        Box row one
                                        </li>
                                                    <li class="Box-row">
                                                        Box row two
                                        </li>
                                                </ul>

                                            </div>
                                        </ul>
                                    </details>
                                    <details class="dropdown details-reset details-overlay d-inline-block">
                                        <summary class="text-gray p-2 d-inline" aria-haspopup="true">
                                            Dropdown
                                <i class="fas fa-angle-down"></i>
                                        </summary>

                                        <ul class="dropdown-menu dropdown-menu-se">
                                            <div class="Box">
                                                <div class="Box-header">
                                                    Filter by X
                                    </div>
                                                <div class="Box-header">
                                                    <input class="form-control input-block" type="text" placeholder="Full-width input" aria-label="Full-width input" />
                                                </div>
                                                <ul>
                                                    <li class="Box-row">
                                                        Box row one
                                        </li>
                                                    <li class="Box-row">
                                                        Box row two
                                        </li>
                                                </ul>

                                            </div>
                                        </ul>
                                    </details>
                                </div>
                            </nav>
                        </div>
                        <div class="Box-row Box-row--hover-gray">
                            Dummy data
                          
            </div>
                        <div class="Box-row Box-row--hover-gray">
                            .Box-row--yellow
            </div>
                        <div class="Box-row Box-row--hover-gray">
                            .Box-row--hover-blue
            </div>
                        <div class="Box-row Box-row--hover-gray">
                            .Box-row--blue
            </div>
                    </div>

                </div>
                <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                    Food truck fixie
                    locavore, accusamus mcsweeney's marfa nulla single-origin coffee squid. Exercitation +1 labore velit,
                    blog sartorial PBR leggings next level wes anderson artisan four loko farm-to-table craft beer twee.
                    Qui photo booth letterpress, commodo enim craft beer mlkshk aliquip jean shorts ullamco ad vinyl cillum
                    PBR. Homo nostrud organic, assumenda labore aesthetic magna delectus mollit. Keytar helvetica VHS
                    salvia yr, vero magna velit sapiente labore stumptown. Vegan fanny pack odio cillum wes anderson 8-bit,
                    sustainable jean shorts beard ut DIY ethical culpa terry richardson biodiesel. Art party scenester
                    stumptown, tumblr butcher vero sint qui sapiente accusamus tattooed echo park.
    </div>
                <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                    Etsy mixtape
                    wayfarers, ethical wes anderson tofu before they sold out mcsweeney's organic lomo retro fanny pack
                    lo-fi farm-to-table readymade. Messenger bag gentrify pitchfork tattooed craft beer, iphone skateboard
                    locavore carles etsy salvia banksy hoodie helvetica. DIY synth PBR banksy irony. Leggings gentrify
                    squid 8-bit cred pitchfork. Williamsburg banh mi whatever gluten-free, carles pitchfork biodiesel fixie
                    etsy retro mlkshk vice blog. Scenester cred you probably haven't heard of them, vinyl craft beer blog
                    stumptown. Pitchfork sustainable tofu synth chambray yr.
    </div>
            </div>

        </div>
    );
  }
}
