import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import logo from '../img/ac-logo-color.png';
import Axios from '../config/axios';
import Globals from '../config/globals';
import { smallBox } from "../common/utils/functions";
import { authenticator } from 'otplib';
import cookie from 'react-cookies';

class TFALogin extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formFields: {
                tfaInput: '',
                trustdevice: false
            }

        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.verify2FA = this.verify2FA.bind(this);
        this.goLogin = this.goLogin.bind(this);

        authenticator.options = {
            step: 30,  // default = 30
            window: 10 // default = 0
        };
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        // added callback function after state.formFields has been updated to invoke a callback to parent
        // component to set form isDirty variable.
        this.setState({ formFields: { ...this.state.formFields, [name]: value } });
    }

    verify2FA = async (event) => {
        event.preventDefault();
        let validCode = false;

        let postdata = {};
        postdata.email = this.props.userid;
        postdata.password = this.state.formFields.tfaInput;

        await Axios.post(`/api/Verify2FA`, postdata
        ).then(response => {
            validCode = response.data;
        }).catch(error => {
            console.log(error);
        });

        if (validCode) {
            const timeInSeconds = 3600;
            const expires = new Date();
            expires.setDate(Date.now() + timeInSeconds * 60 * 60 * 24 * 14);
            if (this.state.formFields.trustdevice === true) {
                cookie.save('bypass2FA' + this.props.userid, true, { path: '/', expires, maxAge: timeInSeconds });
            }
            this.props.validateUser();
        }
        else {
            smallBox({
                title: "Notice",
                content: "<i class='fa fa-clock-o'></i> <i>Invalid Code.</i>",
                color: "#C46A69",
                iconSmall: "fa fa-times fa-2x fadeInRight animated",
                timeout: 4000
            });
        }
    }

    goLogin() {
        this.props.goLogin();
    }

    render() {
        return (
            <div className="full-height">
                <div className="top-header bg-dark">Automated Concepts Client Portal - Login</div>
                <div className="row">
                    <div className="left-panel-login">
                        <img src={logo} className="width100" alt="Automated Concepts, LLC" />
                    </div>
                    <div className="divider" style={{ border: '1px solid rgb(224, 224, 224)', marginTop: '40px', marginBottom: '40px' }}></div>
                    <div className="right-panel-login col padding0">

                        <form id="login-form" className="smart-form client-form" autoComplete="off">

                            <fieldset>

                                <section >
                                    <input type="hidden" value="" />
                                    <label className="label">Enter the security code from the authenticator app on your mobile device</label>
                                    <label className="input">
                                        <i className="icon-append fa fa-mobile" />
                                        <input autoFocus className="input" name="tfaInput" onChange={this.handleInputChange} value={this.state.formFields.tfaInput} />
                                        <b className="tooltip tooltip-top-right"><i className="fa fa-user txt-color-teal" /> 6-digit code</b>
                                    </label>
                                </section>



                                <section style={{ textAlign: "left" }}>
                                    <label className="">
                                        <input type="checkbox" name="trustdevice" id="trustdevice" value={this.state.formFields.trustdevice} onChange={this.handleInputChange} />
                                        <i /> Trust this device. Security code will not be requested any more on this device.
                                                                </label>
                                </section>


                            </fieldset>
                            
                            <footer>
                                <div className="btnRightContainer">
                                    <button onClick={(e) => { this.verify2FA(e); }} className="btn btn-primary">
                                        Submit
                                                </button>
                                </div>
                                <div className="row goBackLogin">
                                    <a onClick={this.goLogin}><i className="fa fa-arrow-left"></i> Go back to login</a>
                                </div>
                                <br />
                                {this.state.validUserFound === false &&
                                    <div>
                                        <span style={{ color: 'red' }}><h5>Invalid User Credentials</h5></span>
                                    </div>
                                }
                            </footer>
                        </form>

                    </div>
                </div>
            </div>
        );
    }

}

export default withRouter(TFALogin);