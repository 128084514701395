import React, { Component } from 'react';
import Login from './Login';

export class Home extends Component {
  static displayName = Home.name;


    componentDidMount() {
        this.props.history.push({
            state: { page: 'home' }
        });
    }
  render () {
      return (
          <div className="container">
              <Login path="login" />
          </div>
    );
  }
}
