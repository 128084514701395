import React, { Component, useState } from 'react';
//import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { NavDropdown } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import './NavMenu.css';
import Globals from '../config/globals';
import { withRouter } from "react-router-dom";
import Axios from '../config/axios';
import $ from 'jquery';

import cookie from 'react-cookies';

class NavMenu extends Component {
    static displayName = NavMenu.name;
    _isMounted = false;

  constructor (props) {
    super(props);
    this.container = React.createRef();
    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
        collapsed: true,
        userType: "",
        encryptedEmail: "",
        sidebarOpen: false,
        company: {},
        suppressEmails: Globals.userInfo.suppressEmails,
        suppressAllEmails: Globals.userInfo.suppressAllEmails

      };
      this.toggleSuppressEmail = this.toggleSuppressEmail.bind(this);
      this.toggleSuppressAllEmail = this.toggleSuppressAllEmail.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleClick = this.handleClick.bind(this);
      this.showSideNav = this.showSideNav.bind(this);
      this.directToAddProject = this.directToAddProject.bind(this);
      this.getCompanyById = this.getCompanyById.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        document.addEventListener("mousedown", this.handleClickOutside);
        if (this._isMounted) {
            if (Globals.userInfo.userType === 'Admin') {
                this.setState({
                    userType: "System Admin"
                });
            }
        }
        

        this.getCompanyById();
    }

    componentDidUpdate() {
            switch (this.props.location.pathname) {
                case '/your-secure-documents':
                    $("#secureDocsBtn").addClass("orangeHighlight");
                    $("#homeBtn").removeClass("orangeHighlight");
                    $("#backupsBtn").removeClass("orangeHighlight");
                    break;
                case '/your-projects':
                    $("#secureDocsBtn").removeClass("orangeHighlight");
                    $("#homeBtn").addClass("orangeHighlight");
                    $("#backupsBtn").removeClass("orangeHighlight");
                    break;
                case '/your-backups':
                    $("#secureDocsBtn").removeClass("orangeHighlight");
                    $("#homeBtn").removeClass("orangeHighlight");
                    $("#backupsBtn").addClass("orangeHighlight");
                    break;
                default:
                    $("#secureDocsBtn").removeClass("orangeHighlight");
                    $("#homeBtn").removeClass("orangeHighlight");
                    $("#backupsBtn").removeClass("orangeHighlight");
                    break;
        }        
    }

    componentWillUnmount() {
        this._isMounted = false;
        document.removeEventListener("mousedown", this.handleClickOutside);
        
    }
    handleClickOutside = event => {
        if (this.container.current && !this.container.current.contains(event.target)) {
            this.setState({
                sidebarOpen: false,
            });
        }
    };

    toggleSuppressEmail(e) {
        e.preventDefault();

        let postdata = {};
        postdata.id = Globals.userInfo.users_Id;
        postdata.boolVal = !this.state.suppressEmails;
        Axios.post(`/api/SetUserSuppressEmails`, postdata
        ).then(response => {
            this.setState({
                suppressEmails: !this.state.suppressEmails
            });
        }).catch(error => {
            console.log(error);
        });

        
    }

    toggleSuppressAllEmail(e) {
        e.preventDefault();

        this.setState({
            suppressAllEmails: !this.state.suppressAllEmails
        }, () => { Globals.userInfo.suppressAllEmails = this.state.suppressAllEmails; });

    }

    getCompanyById() {
        let postdata = {};
        postdata.companies_id = Globals.userInfo.companies_id;

        Axios.post(`/api/GetCompanyById`, postdata
        ).then(response => {
            Globals.userInfo.company_name = response.data.name;
            this.setState({
                company: response.data
            });
        }).catch(error => {
            console.log(error);
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({ sidebarOpen: false, company: {} })
        let userInfo = Globals.userInfo;
        userInfo.email = "";
        userInfo.fName = "";
        userInfo.lName = "";
        userInfo.userType = "";
        userInfo.company_name = "";
        userInfo.bearer = "";
        userInfo.isLoggedIn = false;
        window.sessionStorage.removeItem('userInfo');
        //window.sessionStorage.setItem("userInfo", JSON.stringify(userInfo));
        cookie.remove('userInfo', { path: '/' });
        let path = '/login';
        this.props.history.replace(path);

    }

    handleClick(event) {
        event.preventDefault();
        this.setState({
            sidebarOpen: false
        });
        switch (event.target.id) {
            case 'changePasswordBtn':
                $("#secureDocsBtn").removeClass("orangeHighlight");
                $("#homeBtn").removeClass("orangeHighlight");
                $("#backupsBtn").removeClass("orangeHighlight");
                let postdata = {};
                postdata.email = Globals.userInfo.email;

                Axios.post(`/api/EncryptEmail`, postdata
                ).then(response => {
                    this.setState({
                        encryptedEmail: response.data
                    }, () => {
                        var string = '' + this.state.encryptedEmail;
                        this.props.history.push({
                            pathname: '/reset-password/' + string + '/1'
                        });
                    });
                }).catch(error => {
                    console.log(error);
                });
                break;
            case 'homeBtn':
                $(".navbar-collapse").removeClass("show");
                $("#homeBtn").addClass("orangeHighlight");
                $("#secureDocsBtn").removeClass("orangeHighlight");
                $("#backupsBtn").removeClass("orangeHighlight");
                let path = '/your-projects';
                this.props.history.replace(path);
                break;
            case 'secureDocsBtn':
                $(".navbar-collapse").removeClass("show");
                $("#secureDocsBtn").addClass("orangeHighlight");
                $("#homeBtn").removeClass("orangeHighlight");
                $("#backupsBtn").removeClass("orangeHighlight");
                let path2 = '/your-secure-documents';
                this.props.history.replace(path2);
                break;
            case 'backupsBtn':
                $(".navbar-collapse").removeClass("show");
                $("#backupsBtn").addClass("orangeHighlight");
                $("#homeBtn").removeClass("orangeHighlight");
                $("#secureDocsBtn").removeClass("orangeHighlight");
                let path3 = '/your-backups';
                this.props.history.replace(path3);
                break;
            default:
                break;
        }

        
    }

    showSideNav() {
        this.setState({ sidebarOpen: !this.state.sidebarOpen })
    }

    directToAddProject() {

        this.setState({
            sidebarOpen: false,
        });

        this.props.history.push({
            pathname: '/add-project',
            state: { companyName: this.state.company.name }

        });
    }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
    }

    render() {
        const show = (this.state.sidebarOpen) ? "show2" : "";
        const toggleDisplay = (this.state.sidebarOpen) ? "toggleDisplay" : "";
        console.log(Globals.userInfo.isLoggedIn);
       
        return (
            <div>
                {Globals.userInfo.isLoggedIn &&

                    

                    <header className="navbar navbar-dark bg-dark">
                        <div className="header-body" id="navbar">
                            <div className="">
                                <div className="header-row">
                                    <div className="header-column noFlexGrow">

                                        <div className="header-row">
                                            <div className="navbar-brand">
                                                Automated Concepts Customer Portal
                                        </div>
                                        </div>
                                    </div>

                                    <div className="header-column noFlexGrow">

                                    <div className="header-row displayNavContents">
                                        <a className={'nav-link color-orange'} id="homeBtn" onClick={this.handleClick}>Your Projects</a>
                                        
                                        {Globals.userInfo.accessToSecureDocuments &&
                                            <a className={'nav-link color-orange'} id="secureDocsBtn" onClick={this.handleClick}>Your Secure Documents</a>
                                        }
                                        {Globals.userInfo.accessToBackups &&
                                            <a className={'nav-link color-orange'} id="backupsBtn" onClick={this.handleClick}>Your Backups</a>
                                        }
                                        </div>
                                    </div>

                                    <div className="header-column justify-content-end">

                                        <div className="header-row justify-content-end">
                                            <div className="header-nav header-nav-links">
                                                <div className="header-nav-main header-nav-main-square header-nav-main-effect-2 header-nav-main-sub-effect-1">
                                                    <nav>
                                                        <ul className="nav nav-pills" id="mainNav">

                                                        <button className="btn projectDetailsSubmitBtn margin5 fontSize20" onClick={this.directToAddProject}>Submit New Request</button>
                                                        
                                                        <NavDropdown className="displayNavContents" id="collasible-nav-dropdown" title={<span>{Globals.userInfo.fName} {Globals.userInfo.lName} ({Globals.userInfo.userType})<br /><span className="floatRight companyInNav">{Globals.userInfo.company_name ? Globals.userInfo.company_name : Globals.userInfo.companyName}</span></span>} id="collasible-nav-dropdown">
                                                            <NavDropdown.Item>
                                                                <label>Suppress emails for my activities?</label> <input type="checkbox" name="suppressEmails" checked={this.state.suppressEmails} onChange={e => this.toggleSuppressEmail(e)} onSelect={e => e.stopPropagation()}/>
                                                            </NavDropdown.Item>
                                                            {Globals.userInfo.userType === 'Admin' &&
                                                                <NavDropdown.Item>
                                                                    <label>Suppress ALL email activity</label> <input type="checkbox" name="suppressAllEmails" checked={this.state.suppressAllEmails} onChange={e => this.toggleSuppressAllEmail(e)} onSelect={e => e.stopPropagation()} />
                                                                </NavDropdown.Item>
                                                            }
                                                            <NavDropdown.Item id="changePasswordBtn" onClick={this.handleClick}>Change Password</NavDropdown.Item>
                                                            <NavDropdown.Item onClick={this.handleSubmit}>Logout</NavDropdown.Item>
                                                        </NavDropdown>
                                                       
                                                        </ul>
                                                    </nav>
                                                </div>
                                            </div>

                                            <button className={"navbar-toggler " + toggleDisplay} id="hamburger" data-toggle="collapse" data-target='' onClick={this.showSideNav}>
                                                <span className="navbar-toggler-icon"></span>
                                            </button>

                                            <div id="collapseNav" className={"sidenav " + show} ref={this.container}>
                                                <a className="closebtn black-font" onClick={this.showSideNav}>&times;</a>

                                            <a className={'black-font'} id="homeBtn" onClick={this.handleClick}>Your Projects</a>
                                            {Globals.userInfo.accessToSecureDocuments &&
                                                <a className={'black-font'} id="secureDocsBtn" onClick={this.handleClick}>Your Secure Documents</a>
                                            }
                                            {Globals.userInfo.accessToBackups &&
                                                <a className={'black-font'} id="backupsBtn" onClick={this.handleClick}>Your Backups</a>
                                            }
                                            <a className={'black-font'} id="changePasswordBtn" onClick={this.handleClick}>Change Password</a>
                                            <a className={'black-font'} onClick={this.handleSubmit}>Logout - {Globals.userInfo.fName} {Globals.userInfo.lName}</a>
                                            
                                                <div className="paddingSideNav">
                                                <button className="btn margin5" onClick={this.directToAddProject}>Submit New Request</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </header>
                }
            </div>
    );
  }
}

export default withRouter(NavMenu);