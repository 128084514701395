import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { Counter } from './components/Counter';
import UserHome from './components/user/UserHome';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import SecureDocuments from './components/user/SecureDocuments';
import Backups from './components/user/Backups';
import Globals from './config/globals';
import Axios from './config/axios';
import './css/site.css';
import './css/login.css';
import './css/table.css';
import './css/editorStyles.css';
import './assets/css/smartadmin-production.css';
import './assets/css/smartadmin-production-plugins.css';
import Login from './components/Login';
import ProjectDetails from './components/user/ProjectDetails';
import AddProject from './components/user/AddProject';
import cookie from 'react-cookies';
import { withRouter } from "react-router-dom";


class App extends Component {
  static displayName = App.name;
    componentDidMount() {
        
        if (!Globals.userInfo.isLoggedIn || !cookie.load('userInfo')) {
            let path3 = '/';
            this.props.history.replace(path3);
        }

        window.addEventListener(
            "beforeunload",
            cookie.remove('userInfo', { path: '/' })
        );
    }
    render() {
        if (window.sessionStorage.getItem("userInfo")) {
            const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));
            window.sessionStorage.setItem("userInfo", JSON.stringify(userInfo));
            Globals.userInfo = userInfo;
            let postdata = {};
            postdata.email = userInfo.email;
            let bearer = Globals.userInfo.bearer;
            Axios.post(`/api/GetUserByEmail`, postdata
            ).then(response => {
                Globals.userInfo = response.data;
                Globals.userInfo.isLoggedIn = true;
                Globals.userInfo.company_name = response.data.companyName;
                Globals.userInfo.companies_id = response.data.companies_Id;
                Globals.userInfo.bearer = bearer;
            }).catch(error => {
                console.log(error);
            });
        }
        else if (cookie.load('userInfo')) {
            const userEmail = cookie.load('userInfo');
            let postdata = {};
            let userInfo = {};
            let bearer = Globals.userInfo.bearer;
            const timeInSeconds = 3600;
            const expires = new Date();
            expires.setDate(Date.now() + timeInSeconds * 60 * 60 * 24 * 14);

            cookie.save('userInfo', userEmail, { path: '/', expires, maxAge: timeInSeconds });

            postdata.email = userEmail;

            Axios.post(`/api/GetUserByEmail`, postdata
            ).then(response => {
                Globals.userInfo = response.data;
                Globals.userInfo.isLoggedIn = true;
                Globals.userInfo.company_name = response.data.companyName;
                Globals.userInfo.companies_id = response.data.companies_Id;
                Globals.userInfo.bearer = bearer;
            }).catch(error => {
                console.log(error);
            });

        }
        return (
        <div>
            {
                this.props.location.pathname === '/' || this.props.location.pathname === '/login' ? (
                    <div>
                            <Route exact path='/' component={Home} />
                            <Route exact path='/login' component={Login} />
                            
                    </div>
                ) : (
                        <Layout>
                                <Route path='/counter' component={Counter} />
                                <Route path='/your-projects' component={UserHome} />
                                <Route path='/project-details' component={ProjectDetails} />
                                <Route path='/add-project' component={AddProject} />
                                <Route path='/your-secure-documents' component={SecureDocuments} />
                                <Route path='/your-backups' component={Backups} />
                                <Route path='/forgot-password' component={ForgotPassword} />
                                <Route path="/reset-password/:id/:date" component={ResetPassword} />
                        </Layout>)
            }
        </div>
      //<Layout>
      //  <Route exact path='/' component={Home} />
      //  <Route path='/counter' component={Counter} />
      //  <Route path='/your-projects' component={UserHome} />
      //  <Route exact path='/login' component={Login} />
      //  <Route path='/project-details' component={ProjectDetails} />
      //  <Route path='/add-project' component={AddProject} />
      //  <Route path='/forgot-password' component={ForgotPassword} />
      //  <Route path="/reset-password/:id/:date" component={ResetPassword} />
      //  <Route path='/your-secure-documents' component={SecureDocuments} />
      //  <Route path='/your-backups' component={Backups} />
      //</Layout>
    );
  }
}

export default withRouter(App);