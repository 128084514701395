import React, { useCallback, Component } from 'react'
import { withRouter } from "react-router-dom";
import { useDropzone } from 'react-dropzone';
import Axios from '../../config/axios';
import Globals from '../../config/globals';
import { smallBox, SmartMessageBox } from "../../common/utils/functions";
import { Button, Modal } from 'react-bootstrap';
import Moment from 'moment';

function MyDropzone(props) {
    const maxSize = 1048576;

    const onDrop = useCallback(acceptedFiles => {
        const reader = new FileReader()
        let file = acceptedFiles[0];

        reader.onabort = () => console.log('file reading was aborted')
        reader.onerror = () => console.log('file reading has failed')
        reader.onload = async function () {
            let postdata = {
                attachments: {}
            };

            let formdata = new FormData();
            formdata.append('file', file);
            formdata.append('addedBy', Globals.userInfo.users_Id);
            postdata.file = formdata;
            props.showModal(file);
            
        }
        reader.readAsArrayBuffer(file);
    }, [])

    const { isDragActive, getRootProps, getInputProps, isDragReject, rejectedFiles } = useDropzone({
        onDrop,
        minSize: 0,
    });

    const isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;

    return (
        <div className="dropzoneContainer secureDropzoneContainer text-center">
            <div {...getRootProps()}>
                <input {...getInputProps()} />
                <i className="fas fa-paperclip paperclip-lg"></i>
                {!isDragActive && 'Click here or drop a file to upload'}
                {isDragActive && !isDragReject && "Drop it like it's hot!"}
                {isDragReject && "File type not accepted, sorry!"}
                {isFileTooLarge && (
                    <div className="text-danger mt-2">
                        File is too large.
          </div>
                )}
            </div>
        </div>
    );

}

function Download(name, id) {

    let postdata = {};
    postdata.companies_id = id;

    Axios.post(`/api/DownloadUserAttachment`, postdata, { responseType: 'blob' }
    ).then(response => {

        //const url = window.URL.createObjectURL(new Blob([response.data]));
        //const link = document.createElement('a');
        //link.href = url;
        //link.setAttribute('download', name);
        //document.body.appendChild(link);
        //link.click();

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        // the filename you want
        a.download = name;
        //document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);

    }).catch(error => {
        console.log(error);
    });

}

function DisplayFiles(props) {
    return (

        <div>
            <div className="row">
                <div className="col-lg-6">
                    <h3>My Secure Documents</h3>
                    {props.files.length > 0 ? (
                        <div>
                            {props.files.map((file, idx) => {
                                return (

                                    <div className="fileLinkContainer" key={file.secureDocuments_Id}>
                                        <div className="inputName">{file.fileDescription}</div>
                                        <p><em className="fontSize14">updated by {file.inputName} on {Moment(file.dateAdded).format('L')} at {Moment(file.dateAdded).format('LT')}</em></p>
                                        <i className="fas fa-paperclip paperclip"></i><a className="fileLink" onClick={() => Download(file.fileName, file.secureDocuments_Id)} key={file.secureDocuments_Id}>{file.fileName}</a><div className="trashHover"><a onClick={() => deleteFile(file.secureDocuments_Id, file.fileName, props)}><i className="fas fa-trash-alt"></i></a></div>
                                    </div>

                                );
                            })}
                        </div>
                    ): (<p><strong><em>No Files Found</em></strong></p>)}
            
                </div>
                <div className="col-lg-6">
                    {props.companyName === "" ? (
                        <h3>'Company' Documents</h3>
                    ): (
                            <h3>{props.companyName} Documents</h3>
                        )}
                    {props.companyFiles.length > 0 ? (
                        <div>
                            {props.companyFiles.map((file, idx) => {
                                return (

                                    <div className="fileLinkContainer" key={file.secureDocuments_Id}>
                                        <div className="inputName">{file.fileDescription}</div>
                                        <p><em className="fontSize14">updated by {file.inputName} on {Moment(file.dateAdded).format('L')} at {Moment(file.dateAdded).format('LT')}</em></p>
                                        <i className="fas fa-paperclip paperclip"></i><a className="fileLink" onClick={() => Download(file.fileName, file.secureDocuments_Id)} key={file.secureDocuments_Id}>{file.fileName}</a><div className="trashHover"><a onClick={() => deleteFile(file.secureDocuments_Id, file.fileName, props)}><i className="fas fa-trash-alt"></i></a></div>
                                    </div>

                                );
                            })}
                        </div>
                    ) : (<p><strong><em>No Files Found</em></strong></p>)}
                    
                </div>
            </div>

        </div>
    );
}

function deleteFile(id, name, props) {
    let postdata = {};
    postdata.companies_id = id;

    SmartMessageBox(
        {
            title: "You are about to delete this file.",
            content:
                "Are you sure you would like to proceed?",
            buttons: "[No][Yes]"
        },
        (ButtonPressed) => {
            if (ButtonPressed === "Yes") {
                Axios.post(`/api/DeleteUserAttachment`, postdata
                ).then(response => {

                    props.toUpdate();
                    props.toUpdate2();
                    //updateActivity(name, "delete", props.projectID);
                    smallBox({
                        title: "Notice",
                        content: "<i class='fa fa-clock-o'></i> <i>Attachment has been deleted</i>",
                        color: "#659265",
                        iconSmall: "fa fa-check fa-2x fadeInRight animated",
                        timeout: 4000
                    });
                }).catch(error => {
                    console.log(error);
                });
            }
            if (ButtonPressed === "No") {
                return 0;
            }
        }
    );


}

class SecureDocuments extends Component {

    constructor(props) {
        super(props);

        this.state = {
            files: [],
            companyFiles: [],
            showAttachmentsModal: false,
            singleFile: {},
            singleFileDescription: "",
            isPrivate: true,
            companyName: "",
            companies: [],
            formFields: {
                company: "Automated Concepts"
            },
            company: {}
        };

        this.refreshList = this.refreshList.bind(this);
        this.getProjectFiles = this.getProjectFiles.bind(this);
        this.getCompanyFiles = this.getCompanyFiles.bind(this);
        this.showModal = this.showModal.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.getCompanyById = this.getCompanyById.bind(this);
        this.getCompanies = this.getCompanies.bind(this);
        this.handleCompanyChange = this.handleCompanyChange.bind(this);
        this.getChangedCompanyByName = this.getChangedCompanyByName.bind(this);
    }

    componentDidMount() {

        if (!Globals.userInfo.accessToSecureDocuments) {
            let path = '/your-projects';
            this.props.history.replace(path);
        }

        this.getProjectFiles();
        this.getCompanyFiles();
        this.getCompanyById();
        this.getCompanies();
        this.props.history.push({
            state: { page: 'your-secure-documents' }
        });
    }

    showModal(param) {
        this.setState({ showAttachmentsModal: true, singleFile: param });
    }

    refreshList() {
        this.props.refreshList();
    }

    handleClick(event) {
        event.preventDefault();
        switch (event.target.id) {
            case 'closeModalLink':
                this.setState({
                    showAttachmentsModal: false
                });
                break;
            default:
                break;
        }
    }

    handleCompanyChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (name === 'company') {

            this.getChangedCompanyByName(value);
        };

        this.setState({ formFields: { ...this.state.formFields, [name]: value } });
    }

    getChangedCompanyByName(name) {
        let postdata = {};
        postdata.company_name = name;
        Axios.post(`/api/GetCompanyByName`, postdata
        ).then(response => {
            this.setState({
                company: response.data
            }, () => { this.getCompanyFiles(this.state.company); this.getCompanyById(this.state.company); });
        }).catch(error => {
            console.log(error);
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        var isPrivate = null;
        var singleFileDescription = "";

        if (target.type === 'radio') {
            isPrivate = value === 'true' ? true : false;
            this.setState({
                isPrivate
            });
        };

        if (name === 'fileDescription') {
            
            singleFileDescription = value;
            this.setState({
                singleFileDescription
            })
        };
    }

    addProjectFile(file) {
        let formdata = new FormData();
        formdata.append('file', file);
        formdata.append('addedBy', Globals.userInfo.users_Id);
        formdata.append('fullName', Globals.userInfo.fName + " " + Globals.userInfo.lName);
        formdata.append('fileDescription', this.state.singleFileDescription);
        formdata.append('companies_Id', Globals.userInfo.companies_id);
        formdata.append('isPrivate', this.state.isPrivate);
        Axios.post(`/api/AddUserAttachment`, formdata
        ).then(response => {
            this.setState({
                showAttachmentsModal: false,
                singleFileDescription: ""
            });
            //updateActivity(file.name, "add", props.projectID);
            smallBox({
                title: "Notice",
                content: "<i class='fa fa-clock-o'></i> <i>Attachment has been added</i>",
                color: "#659265",
                iconSmall: "fa fa-check fa-2x fadeInRight animated",
                timeout: 4000
            });
            this.getProjectFiles();
            this.getCompanyFiles();
        }).catch(error => {
            console.log(error);
        });
    }

    getProjectFiles() {
        let postdata = {};
        postdata.companies_id = Globals.userInfo.users_Id;
        Axios.post(`/api/GetUserFiles`, postdata
        ).then(response => {
            this.setState({
                files: response.data
            });
        }).catch(error => {
            console.log(error);
        });
    }

    getCompanies() {
        Axios.post(`/api/GetCompaniesList`
        ).then(response => {
            this.setState({
                companies: response.data
            });
        }).catch(error => {
            console.log(error);
        });
    }

    getCompanyFiles(company) {
        let postdata = {};

        if (company) {
            postdata.companies_id = company.companies_Id;
        }
        else {
            postdata.companies_id = Globals.userInfo.companies_id;
        }
        
        Axios.post(`/api/GetCompanyFiles`, postdata
        ).then(response => {
            this.setState({
                companyFiles: response.data
            });
        }).catch(error => {
            console.log(error);
        });
    }

    getCompanyById(company) {
        let postdata = {};

        if (company) {
            postdata.companies_id = company.companies_Id;
        }
        else {
            postdata.companies_id = Globals.userInfo.companies_id;
        }

        Axios.post(`/api/GetCompanyById`, postdata
        ).then(response => {
            var name = "";
            if (response.data === "") {
                name = "";
            }
            else {
                name = response.data.name;
            }
            this.setState({
                companyName: name
            });
        }).catch(error => {
            console.log(error);
        });
    }

    render() {
        return (
            <div>
            <div className="adminSearchDiv">
                    {Globals.userInfo.userType === "Admin" &&
                <div className="col-lg-4 col-sm-6 col-xs-12">
                    <dt><label>Select Company</label></dt>
                        <dd>
                            
                                <select className="form-control" id="example-select" name="company" value={this.state.formFields.company} onChange={this.handleCompanyChange}>
                                    <option key='all' value='all'>All</option>
                                    {this.state.companies.map((company, idx) => <option key={idx} value={company.name} >{company.name}</option>)}
                                </select>
                            
                    </dd>
                    </div>
                }
            </div>
            <div className="paddingTop90">
                <MyDropzone toUpdate={this.getProjectFiles} projectID={this.props.projectID} showModal={(param) => this.showModal(param)} />
                <DisplayFiles files={this.state.files} companyFiles={this.state.companyFiles} companyName={this.state.companyName} toUpdate={this.getProjectFiles} toUpdate2={this.getCompanyFiles} refreshList={this.refreshList} projectID={this.props.projectID} />
                <Modal size='md' show={this.state.showAttachmentsModal} onHide={this.showAttachmentsModal} backdrop='static'>
                    <Modal.Header>
                        Save Document Details
                        <a className="alignRight" id="closeModalLink" onClick={this.handleClick}>&times;</a>
                    </Modal.Header>
                    <Modal.Body>
                        <section className="col col-xs-12">

                            <label className="control-label" htmlFor="majcatselect">Add Document Details for: <p><strong><em>{this.state.singleFile.name}</em></strong></p></label>
                            <textarea style={{ width: '100%' }} rows="4" name="fileDescription" placeholder="Document Description" onChange={this.handleInputChange} />
                            <input type="radio" name="isPrivate" value="true" onChange={this.handleInputChange}></input>&nbsp;Private Document&nbsp;&nbsp;
                            <input type="radio" name="isPrivate" value="false" onChange={this.handleInputChange}></input>&nbsp;Company Document

                        </section>
                    </Modal.Body>
                    <Modal.Footer>
                        <a id="closeModalLink" onClick={this.handleClick}>Cancel</a>
                        <Button id="btnSaveChanges" onClick={() => this.addProjectFile(this.state.singleFile)} className="btn projectDetailsSubmitBtn">Save Document Details</Button>
                    </Modal.Footer>
                </Modal>
                </div>
            </div>
        );
    }
}

export default withRouter(SecureDocuments);