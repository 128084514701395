import React, { useCallback, Component } from 'react'
import { withRouter } from "react-router-dom";
import { useDropzone } from 'react-dropzone';
import Axios from '../../../config/axios';
import Globals from '../../../config/globals';
import { smallBox, SmartMessageBox } from "../../../common/utils/functions";


//function onFilesChange(files) {
//    console.log(files);

//    const onDrop = useCallback(acceptedFiles => {
//        const reader = new FileReader()

//        reader.onabort = () => console.log('file reading was aborted')
//        reader.onerror = () => console.log('file reading has failed')
//        reader.onload = () => {
//            // Do whatever you want with the file contents
//            console.log(reader);
//            //const binaryStr = reader.result;

//            const binaryStr = reader.result;
//            console.log(binaryStr);
//            //let postdata = {
//            //    attachments: {}
//            //};

//            //postdata.attachments.addedBy_Id = 1;
//            //postdata.attachments.attachmentName = binaryStr;
//            //postdata.attachments.fileName = "Test";
//            //postdata.attachments.projects_Id = "936";
//            //postdata.attachments.dateAdded = new Date();

//            //Axios.post(`/api/AddAttachment`, postdata
//            //).then(response => {
//            //    console.log("It worked");


//            //}).catch(error => {
//            //    console.log(error);
//            //});
//        }

//        files.map(file => reader.readAsBinaryString(file));
//        //acceptedFiles.forEach(file => reader.readAsText(file));
//    }, [])
//}

//function onFilesError(error, file) {
//    console.log('error code ' + error.code + ': ' + error.message)
//}


function MyDropzone(props) {
    const maxSize = 1048576;

    const onDrop = useCallback(acceptedFiles => {
        const reader = new FileReader()
        let file = acceptedFiles[0];
        
        reader.onabort = () => console.log('file reading was aborted')
        reader.onerror = () => console.log('file reading has failed')
        reader.onload = async function () {
            //var arrayBuffer = this.result;
            let postdata = {
                attachments: {}
            };
            ////console.log(Encoding.UTF8.GetString(byteArray));
            //postdata.attachments.addedBy_Id = 1;
            ////postdata.attachments.attachmentName = await file.text();
            ////postdata.testobj = JSON.stringify(Array.from(new Uint8Array(arrayBuffer)));
            //postdata.attachments.fileName = file.name;
            //postdata.attachments.projects_Id = props.projectID;
            //postdata.attachments.dateAdded = new Date();

            let formdata = new FormData();
            formdata.append('file', file);
            formdata.append('addedBy', Globals.userInfo.users_Id);
            formdata.append('projectID', props.projectID);
            postdata.file = formdata;

            Axios.post(`/api/AddAttachment`, formdata
            ).then(response => {
                props.toUpdate();
                updateActivity(file.name, "add", props.projectID, props.project);
                smallBox({
                    title: "Notice",
                    content: "<i class='fa fa-clock-o'></i> <i>Attachment has been added</i>",
                    color: "#659265",
                    iconSmall: "fa fa-check fa-2x fadeInRight animated",
                    timeout: 4000
                });
                }).catch(error => {
                console.log(error);
                });
        }
        reader.readAsArrayBuffer(file);
    }, [])

    const { isDragActive, getRootProps, getInputProps, isDragReject, rejectedFiles } = useDropzone({
        onDrop,
        minSize: 0,
    });

    const isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;

    return (
        <div className="dropzoneContainer text-center">
            <div {...getRootProps()}>
                <input {...getInputProps()} />
                <i className="fas fa-paperclip paperclip-lg"></i>
                {!isDragActive && 'Click here or drop a file to upload'}
                {isDragActive && !isDragReject && "Drop it like it's hot!"}
                {isDragReject && "File type not accepted, sorry!"}
                {isFileTooLarge && (
                    <div className="text-danger mt-2">
                        File is too large.
          </div>
                )}
            </div>
        </div>
    );

}

function updateActivity(name, fromWhere, projectID, project) {
    var string = "";
    if (fromWhere === "add") {
        string = "<li><strong><em>" + name + "</strong></em> has been added </li>";
    }
    else {
        string = "<li><strong><em>" + name + "</strong></em> has been removed </li>";
    }
    

    let postdata = {
        activity: {}
    };

    postdata.activity.inputID = Globals.userInfo.users_Id;
    postdata.activity.inputName = Globals.userInfo.fName + " " + Globals.userInfo.lName;
    postdata.activity.createdDate = new Date();
    postdata.activity.activity = "<p>" + string + "</p>";
    postdata.activity.projectsID = projectID;
    Axios.post(`/api/AddActivity`, postdata
    ).then(response => {
        sendActivityUpdate(postdata.activity.activity, project);
    }).catch(error => {
        console.log(error);
    });

}

function sendActivityUpdate(content, project) {
    let postdata = {
        project: {},
        activity: {}
    };
    console.log(project);
    postdata.project = project.projectsModel;
    postdata.activity.inputName = Globals.userInfo.fName + " " + Globals.userInfo.lName;
    postdata.activity.createdDate = new Date();
    postdata.activity.activity = content;
    postdata.suppressAllEmails = Globals.userInfo.suppressAllEmails;
    Axios.post(`/api/SendActivityUpdate`, postdata
    ).catch(error => {
        console.log(error);
    });
}

function Download(name, id) {

    let postdata = {};
    postdata.companies_id = id;

    Axios.post(`/api/DownloadAttachment`, postdata, { responseType: 'blob' }
    ).then(response => {

        //const url = window.URL.createObjectURL(new Blob([response.data]));
        //const link = document.createElement('a');
        //link.href = url;
        //link.setAttribute('download', name);
        //document.body.appendChild(link);
        //link.click();

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement('a');

        a.style.display = 'none';
        a.href = url;
        // the filename you want
        a.download = name;
        //document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);

    }).catch(error => {
        console.log(error);
        });

}

//function startDownload(arrayBuffer, type, name) {
//    //const reader = new FileReader()

//    let file = new File([arrayBuffer], name, { type: type });
//    console.log(file);
//    const reader = new FileReader()
//    reader.onabort = () => console.log('file reading was aborted')
//    reader.onerror = () => console.log('file reading has failed')
//    reader.onload = function () {
//        var arrayBuffer = this.result;
//        Download2(arrayBuffer, file.type, file.fileName);
//    }
//    //reader.readAsArrayBuffer(file);
//    //reader.onabort = () => console.log('file reading was aborted')
//    //reader.onerror = () => console.log('file reading has failed')
//    //reader.onload = function () {
//    //    var arrayBuffer = this.result;
//    //    Download(arrayBuffer, file.type);
//    //}
//    //    reader.readAsArrayBuffer(file);
    
//}

//function Download2(arrayBuffer, type, name) {
//    var blob = new Blob([arrayBuffer], { type: type });
//    const url = window.URL.createObjectURL(blob);
//    const a = document.createElement('a');
//    a.style.display = 'none';
//    a.href = url;
//    // the filename you want
//    a.download = name;
//    document.body.appendChild(a);
//    a.click();
//    window.URL.revokeObjectURL(url);
//}

function DisplayFiles(props) {
    
    return (
        
        <div>
            {props.files.map((file, idx) => {
                return (
                    <div className="fileLinkContainer" key={file.attachments_Id}>
                        <i className="fas fa-paperclip paperclip"></i><a className="fileLink" onClick={() => Download(file.fileName, file.attachments_Id)} key={file.attachments_Id}>{file.fileName}</a><div className="trashHover"><a onClick={() => deleteFile(file.attachments_Id, file.fileName, props)}><i className="fas fa-trash-alt"></i></a></div>
                    </div>
                );
            })}
        </div>
    );
}

function deleteFile(id, name, props) {
    let postdata = {};
    postdata.companies_id = id;

    SmartMessageBox(
        {
            title: "You are about to delete this file.",
            content:
                "Are you sure you would like to proceed?",
            buttons: "[No][Yes]"
        },
        (ButtonPressed) => {
            if (ButtonPressed === "Yes") {
                Axios.post(`/api/DeleteAttachment`, postdata
                ).then(response => {
                    
                    props.toUpdate();
                    updateActivity(name, "delete", props.projectID, props.project);
                    smallBox({
                        title: "Notice",
                        content: "<i class='fa fa-clock-o'></i> <i>Attachment has been deleted</i>",
                        color: "#659265",
                        iconSmall: "fa fa-check fa-2x fadeInRight animated",
                        timeout: 4000
                    });
                }).catch(error => {
                    console.log(error);
                });
            }
            if (ButtonPressed === "No") {
                return 0;
            }
        }
    );

    
}

class FileInput extends Component {

    constructor(props) {
        super(props);

        this.state = {
            files: []
        };

        this.refreshList = this.refreshList.bind(this);
        this.getProjectFiles = this.getProjectFiles.bind(this);
        this.handleFileClick = this.handleFileClick.bind(this);
    }

    componentDidMount() {
        this.getProjectFiles();
    }

    refreshList() {
        this.props.refreshList();
    }

    getProjectFiles() {
        let postdata = {};
        postdata.companies_id = this.props.projectID;
        Axios.post(`/api/GetProjectFiles`, postdata
        ).then(response => {
            this.setState({
                files: response.data
            });
        }).catch(error => {
            console.log(error);
        });
    }

    handleFileClick(attachment) {
        FileInput.Test();
    }
    

    render() {
        return (
            <div>
                <DisplayFiles files={this.state.files} toUpdate={this.getProjectFiles} refreshList={this.refreshList} projectID={this.props.projectID} />
                <MyDropzone toUpdate={this.getProjectFiles} projectID={this.props.projectID} project={this.props.project} />
            </div>
        );
    }
}

export default withRouter(FileInput);