import React, { Component, Fragment } from 'react';
import { withRouter } from "react-router-dom";
import Axios from '../../config/axios';
import DatePicker from 'react-datepicker';
import Floater from 'react-floater';
import ReactLoading from 'react-loading';
import Globals from '../../config/globals';
import Moment from 'moment';
import { smallBox } from "../../common/utils/functions";
import { Grid, Input } from 'react-spreadsheet-grid'
import cookie from 'react-cookies';

import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class Obj {
    constructor(id, name) {
        this.id = id;
        this.name = name;
    }
}

class UserHome extends Component {
    constructor(props) {
        super(props);
        console.log(props);

        this.state = {
            loading: true,
            companies: [],
            company: {},
            companiesFiltered: [],
            projectTypes: [],
            statuses: [],
            date: null,
            popoverOpen: false,
            projects: [],
            projectResources: [],
            resourceFullNames: [],
            projectsPaginated: [],
            projectGroups: [],
            filteredProjectGroups: [],
            clientContacts: [],
            dataSheetView: false,
            exportData: [],
            formFields: {
                projectType: "all",
                status: "all",
                company: "all",
                group: "all",
                resource: "all",
                dateNeeded: null,
                workExclusive: false,
                sortBy: "",
                isAscending: false,
                priority: "all",
                clientContact: "all"
            },
            sortFormFields: {

            }
        };

        this.generateExportData = this.generateExportData.bind(this);
        this.getCompanies = this.getCompanies.bind(this);
        this.getCompanyIdList = this.getCompanyIdList.bind(this);
        this.getActiveStatuses = this.getActiveStatuses.bind(this);
        this.getActiveProjectTypes = this.getActiveProjectTypes.bind(this);
        this.toggle = this.toggle.bind(this);
        this.toggleView = this.toggleView.bind(this);
        this.getProjectList = this.getProjectList.bind(this);
        this.getProjectResources = this.getProjectResources.bind(this);
        this.getProjectsPaginated = this.getProjectsPaginated.bind(this);
        this.getProjectGroups = this.getProjectGroups.bind(this);
        this.getFilteredProjectGroups = this.getFilteredProjectGroups.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.directToProjectDetails = this.directToProjectDetails.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.directToAddProject = this.directToAddProject.bind(this);
        this.handleSortChange = this.handleSortChange.bind(this);
        this.handleSort = this.handleSort.bind(this);
        this.getClientContacts = this.getClientContacts.bind(this);
        this.keepFormFields = this.keepFormFields.bind(this);
        this.getChangedCompanyByName = this.getChangedCompanyByName.bind(this);

        this.projectDetailsIsDirty = false;
    }

    componentDidMount() {

        if (this.props.location.state && this.props.location.state.searchCriteria) {
            this.keepFormFields(this.props.location.state.searchCriteria);
        }
        else {
            this.getProjectList();
        }

        var url = this.props.location.pathname;
        var length = url.length;
        var apiPath = '/api/DecryptID';
        console.log(url);
        if (url.substr(length - 5) === 'abcde') {
            
            
            apiPath = '/api/DecryptIDFromActivity';
            url = url.substr(0, length - 5);
            console.log(apiPath);
            console.log(url);
        }
        var id = url.substr(15);
        console.log(id);
        if (id) {
            let pdata = {};
            pdata.password = id;
            console.log(pdata);

            

            Axios.post(apiPath, pdata
            ).then(response => {
                id = response.data;
                console.log(response.data);
                
                const timeInSeconds = 3600;
                const expires = new Date();
                expires.setDate(Date.now() + timeInSeconds * 60 * 60 * 24 * 14);

                cookie.save('userInfo', Globals.userInfo.email, { path: '/', expires, maxAge: timeInSeconds });

                console.log(cookie.load("userInfo"));
                console.log(Globals.userInfo.isLoggedIn);
                if (cookie.load("userInfo") || Globals.userInfo.isLoggedIn) {
                    let postdata = {};
                    postdata.id = response.data;
                    Axios.post(`/api/GetProjectByID`, postdata
                    ).then(response => {
                        
                        this.directToProjectDetails(response.data);
                    }).catch(error => {
                        console.log(error);
                    });
                }
                else {
                    console.log(id);
                    this.props.history.push({
                        pathname: '/login'
                    });
                    Globals.tempProjID = id;
                }

            }).catch(error => {
                console.log(error);
            });
        }
        else {
            this.props.history.push({
                state: { page: 'your-projects' }
            });

            if (this.props.location.state) {
                if (this.props.location.state.projectAdded) {
                    smallBox({
                        title: "Notice",
                        content: "<i class='fa fa-clock-o'></i> <i>Project has been added.</i>",
                        color: "#659265",
                        iconSmall: "fa fa-check fa-2x fadeInRight animated",
                        timeout: 4000
                    });
                }
                if (this.props.location.state.projectDeleted) {
                    smallBox({
                        title: "Notice",
                        content: "<i class='fa fa-clock-o'></i> <i>Project has been deleted.</i>",
                        color: "#659265",
                        iconSmall: "fa fa-check fa-2x fadeInRight animated",
                        timeout: 4000
                    });
                }
                if (this.props.location.state.projectCompleted) {
                    smallBox({
                        title: "Notice",
                        content: "<i class='fa fa-clock-o'></i> <i>Project has been completed.</i>",
                        color: "#659265",
                        iconSmall: "fa fa-check fa-2x fadeInRight animated",
                        timeout: 4000
                    });
                }
            }

            this.getCompanies();
            this.getActiveProjectTypes();
            this.getActiveStatuses();

            this.getProjectResources();
            this.getProjectGroups();

            this.getClientContacts(Globals.userInfo.companies_id);

            if (this.props.location.state && this.props.location.state.searchCriteria) {
                this.getChangedCompanyByName(this.props.location.state.searchCriteria.company);
            } else {
                this.getChangedCompanyByName(Globals.userInfo.company_name);
            }
        }

        


    }

    generateExportData() {

        var searchCriteria = '';

        if (this.state.formFields.projectType !== 'all') searchCriteria += "Project Type: " + this.state.formFields.projectType + ", ";
        if (this.state.formFields.status !== 'all') searchCriteria += "Project Status: " + this.state.formFields.status + ", ";
        if (this.state.formFields.company !== 'all') searchCriteria += "Project Company: " + this.state.formFields.company + ", ";
        if (this.state.formFields.resource !== 'all') searchCriteria += "Project Resource: " + this.state.formFields.resource + ", ";
        if (this.state.formFields.dateNeeded !== null) searchCriteria += "Date Needed: " + this.state.formFields.dateNeeded + ", ";
        if (this.state.formFields.priority !== 'all') searchCriteria += "Project Priority: " + this.state.formFields.priority + ", ";
        if (this.state.formFields.clientContact !== 'all') searchCriteria += "Client Contact: " + this.state.formFields.clientContact + ", ";
        if (this.state.formFields.workExclusive !== false) searchCriteria += "Urgent Request: true, ";

        if (searchCriteria === '') {
            searchCriteria = 'All company projects displayed';
        }

        var exportData = [
            [searchCriteria],
            [],
            [
                { value: "Project ID", style: { font: { bold: true } } },
                { value: "Project Name", style: { font: { bold: true } } },
                { value: "Project Priority", style: { font: { bold: true } } },
                { value: "Project Type", style: { font: { bold: true } } },
                { value: "Project Description", style: { font: { bold: true } } }
            ]
        ];
        var proj = this.state.projects;


        for (var i = 0; i < proj.length; i++) {
            var list = [];
            list.push(proj[i].projectsModel.projects_Id);
            list.push(proj[i].projectsModel.projectName);
            list.push(proj[i].projectsModel.priority);
            list.push(proj[i].projectsModel.projectyType);
            list.push(proj[i].projectsModel.projectDetails);

            exportData.push(list);
        }

        var multiDataSet = [
            {
                columns: [
                    { title: "", width: { wpx: 100 } },
                    { title: "", width: { wpx: 100 } },
                    { title: "", width: { wpx: 100 } },
                    { title: "", width: { wpx: 100 } },
                    { title: "", width: { wpx: 100 } }
                
                ],
                data: exportData
            }
        ];

        this.setState({
            exportData: multiDataSet
        });
    }

    toggle() {
        this.setState({
            popoverOpen: !this.state.popoverOpen
        });
    }

    toggleView() {
        this.getProjectList();
        this.setState({
            dataSheetView: !this.state.dataSheetView
        });
    }

    onChange = (date) => {
        var ffCopy = { ...this.state.formFields };
        ffCopy.dateNeeded = date;
        this.setState({ formFields: ffCopy });
    }

    getProjectList() {

        let pdata = {};
        pdata.projectType = this.state.formFields.projectType;
        pdata.status = this.state.formFields.status;
        pdata.company = this.state.formFields.company;
        pdata.group = this.state.formFields.group;
        pdata.resource = this.state.formFields.resource;
        pdata.dateNeeded = this.state.formFields.dateNeeded;
        pdata.workExclusive = this.state.formFields.workExclusive;
        pdata.priority = this.state.formFields.priority;
        pdata.clientContact = this.state.formFields.clientContact;
        pdata.users_Id = Globals.userInfo.users_Id;
        pdata.value = this.state.formFields.mostRecentFirst && this.state.formFields.mostRecentFirst.toString();
        console.log(pdata);
        console.log(Globals.userInfo);
        if (Globals.userInfo.userType === 'Admin') {
            Axios.post(`/api/GetProjectList`, pdata
            ).then(response => {

                this.setState({
                    projects: response.data,
                    loading: false
                }, () => this.generateExportData());

                this.getProjectsPaginated(0);
            }).catch(error => {
                console.log(error);
            });
        }
        else if (Globals.userInfo.userType === 'ClientAdmin') {
            console.log("Companies_ID: " + Globals.userInfo.companies_id);
            pdata.companies_id = Globals.userInfo.companies_id;
            Axios.post(`/api/GetProjectListWithCompanyAsClientAdmin`, pdata
            ).then(response => {

                this.setState({
                    projects: response.data,
                    loading: false
                }, () => this.generateExportData());

                this.getProjectsPaginated(0);
            }).catch(error => {
                console.log(error);
            });
        }
        else {
            pdata.companies_id = Globals.userInfo.companies_id;
            console.log(pdata.companies_id);
            Axios.post(`/api/GetProjectListWithCompany`, pdata
            ).then(response => {

                this.setState({
                    projects: response.data,
                    loading: false
                }, () => this.generateExportData());

                this.getProjectsPaginated(0);

            }).catch(error => {
                console.log(error);
            });
        }

        
    }

    getProjectsPaginated(start, fromWhere) {
        const rowsPerPage = 10;
        if (fromWhere === 'fromPrev' && this.page === 0) {
            return;
        }
        if (fromWhere === 'fromNext' && this.page === (Math.floor(this.state.projects.length / rowsPerPage))) {
            return;
        }
        this.page = start;
        var begin = start * rowsPerPage;
        var projectsList = this.state.projects;
        var projectsPaginated = projectsList.slice(begin, (begin + rowsPerPage));
        this.setState({
            projectsPaginated
        });
    }

    getCompanies() {
        Axios.post(`/api/GetCompaniesList`
        ).then(response => {
            this.setState({
                companies: response.data
            }, () => {
                this.getCompanyIdList();
            });
        }).catch(error => {
            console.log(error);
        });
    }

    getCompanyIdList() {
        let postdata = {};
        postdata.companies_id = Globals.userInfo.users_Id;

        Axios.post(`/api/GetCompanyIdList`, postdata
        ).then(response => {
            this.setState({
                companiesFiltered: response.data
            });
        }).catch(error => {
            console.log(error);
        });
    }


    getActiveProjectTypes() {
        Axios.post(`/api/GetActiveProjectTypes`
        ).then(response => {
            this.setState({
                projectTypes: response.data
            });
        }).catch(error => {
            console.log(error);
        });
    }

    getActiveStatuses() {
        Axios.post(`/api/GetActiveStatuses`
        ).then(response => {
            this.setState({
                statuses: response.data
            });

        }).catch(error => {
            console.log(error);
        });
    }

    getClientContacts(id) {
        let postdata = {};
        postdata.companies_id = id;

        Axios.post(`/api/GetClientContacts`, postdata
        ).then(response => {
            this.setState({
                clientContacts: response.data
            });
        }).catch(error => {
            console.log(error);
        });
    }

    getChangedCompanyByName(name) {
        let postdata = {};
        postdata.company_name = name;
        Axios.post(`/api/GetCompanyByName`, postdata
        ).then(response => {
            this.setState({
                company: response.data
            }, () => { this.getClientContacts(this.state.company.companies_Id); this.getFilteredProjectGroups(this.state.company.companies_Id); });
        }).catch(error => {
            console.log(error);
        });
    }

    getProjectResources() {

        var tempList = [];
        Axios.post(`/api/GetProjectResourcesAsAdmin`
        ).then(response => {
            for (var i = 0; i < response.data.length; i++) {
                var name = response.data[i].fName + " " + response.data[i].lName;
                var id = response.data[i].users_Id;
                var obj = new Obj(id, name);
                tempList.push(obj);
            }
            this.setState({
                projectResources: response.data,
                resourceFullNames: tempList
            });

        }).catch(error => {
            console.log(error);
        });
    }

    getProjectGroups() {


        Axios.post(`/api/GetProjectGroupsAsAdmin`
        ).then(response => {
            this.setState({
                projectGroups: response.data
            });

        }).catch(error => {
            console.log(error);
        });

    }

    getFilteredProjectGroups(id) {
        let postdata = {};
        postdata.companies_id = id;
        Axios.post(`/api/GetProjectGroupsAsClient`, postdata
        ).then(response => {
            this.setState({
                filteredProjectGroups: response.data
            });
        }).catch(error => {
            console.log(error);
        });
    }

    handleClick(event) {
        event.preventDefault();

        switch (event.target.id) {
            case 'projectDetailsCancelLink':
                this.setFormFields();

                break;
            default:
                alert('default condition hit');
                break;
        }


    }

    setFormFields() {
        var ffCopy = { ...this.state.formFields };

        ffCopy.projectType = "all";
        ffCopy.status = "all";
        ffCopy.company = "all";
        ffCopy.group = "all";
        ffCopy.resource = "all";
        ffCopy.dateNeeded = null;
        ffCopy.workExclusive = false;
        ffCopy.clientContact = "all";
        ffCopy.priority = "all";

        this.setState({
            formFields: ffCopy
        }, () => this.getProjectList());
    }

    keepFormFields(searchCriteria) {

        this.setState({
            formFields: searchCriteria
        }, () => { this.getProjectList() });

    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (name === 'company') {

            this.getChangedCompanyByName(value);
        };

        this.setState({ formFields: { ...this.state.formFields, [name]: value } },
            () => {
                if (JSON.stringify(this.state.formFields) !== JSON.stringify(this.formFields)) {
                    this.projectDetailsIsDirty = true;  // dirty
                } else {
                    this.projectDetailsIsDirty = false; // pristine
                }
            });
    }

    handleSortChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({ formFields: { ...this.state.formFields, [name]: value } });
    }

    handleSort() {
        let pdata = {};
        //pdata.value = this.state.formFields.value;
        pdata.isAscending = this.state.formFields.isAscending;

        pdata.projectType = this.state.formFields.projectType;
        pdata.status = this.state.formFields.status;
        pdata.company = this.state.formFields.company;
        pdata.group = this.state.formFields.group;
        pdata.resource = this.state.formFields.resource;
        pdata.dateNeeded = this.state.formFields.dateNeeded;
        pdata.workExclusive = this.state.formFields.workExclusive;
        pdata.value = this.state.formFields.mostRecentFirst.toString();
        console.log(pdata);
        if (Globals.userInfo.userType === 'Admin') {
            Axios.post(`/api/GetProjectList`, pdata
            ).then(response => {

                this.setState({
                    projects: response.data,
                    loading: false
                });

                this.getProjectsPaginated(0);
            }).catch(error => {
                console.log(error);
            });
        }
        else {
            pdata.companies_id = Globals.userInfo.companies_id;

            Axios.post(`/api/GetProjectListWithCompany`, pdata
            ).then(response => {

                this.setState({
                    projects: response.data,
                    loading: false
                });

                this.getProjectsPaginated(0);

            }).catch(error => {
                console.log(error);
            });
        }
    }

    handleSubmit() {
        this.getProjectList();
    }

    createPagination() {
        const displayPageAmount = 5;
        var pages = [];
        var pagesSubset = [];
        var activeClass = false;
        var iterator = Math.ceil((this.state.projects.length / displayPageAmount) / 2);

        for (let i = 0; i < iterator; i++) {
            var pagenum = i + 1;
            (i === this.page) ? activeClass = true : activeClass = false;

            pages.push(<a key={i} className={activeClass ? 'current selected' : ''} onClick={() => this.getProjectsPaginated(i)} aria-label={"Page " + pagenum}>{pagenum}</a>)
        }
        if ((this.page + 1) > pages.length - displayPageAmount) {
            for (let j = (pages.length - displayPageAmount); j < pages.length; j++) {
                pagesSubset.push(pages[j]);
            }
        }
        else {
            for (let j = this.page; j < this.page + displayPageAmount; j++) {
                pagesSubset.push(pages[j]);
            }
        }

        return (
            <nav className="paginate-container" aria-label="Pagination">
                <div className="pagination">
                    <a className={"previous_page " + (this.page !== 0 ? '' : 'disabled')} onClick={() => this.getProjectsPaginated(this.page - 1, "fromPrev")}>Previous</a>
                    {pagesSubset}
                    <a className={"next_page " + (this.page !== iterator - 1 ? '' : 'disabled')} onClick={() => this.getProjectsPaginated(this.page + 1, "fromNext")} aria-label="Next Page">Next</a>
                </div>
            </nav>
        );
    }

    directToProjectDetails(project) {
        Globals.projectData = project;

        this.props.history.push({
            pathname: '/project-details',
            state: { project, searchCriteria: this.state.formFields }
        });
    }

    directToAddProject() {

        this.props.history.push({
            pathname: '/add-project',
            state: { companyName: Globals.userInfo.company_name }

        });
    }

    render() {

        var rows = [];
        if (!this.state.loading) {
            rows = this.state.projects;
        }
        
        console.log(this.state.projects);
        return (
            <Fragment>
                <div className="adminSearchDiv">
                    <div className="row container autoMargin">

                        <h5 className="col-12 greenHeader">Search existing projects - Enter your search criteria</h5>

                        <div className="row">
                        <div className="col-lg-4 col-sm-6 col-xs-12">
                            <dt><label>Select Company</label></dt>
                            <dd>
                                {this.state.companiesFiltered.length < 2 ? (
                                    <div>
                                        {Globals.userInfo.userType !== "Admin" ? (
                                            <input className="form-control" name="company" value={Globals.userInfo.company_name} readOnly />
                                        ) : (
                                                <select className="form-control" id="example-select" name="company" value={this.state.formFields.company} onChange={this.handleInputChange}>
                                                    <option key='all' value='all'>All</option>
                                                    {this.state.companies.map((company, idx) => <option key={idx} value={company.name} >{company.name}</option>)}
                                                </select>
                                            )}

                                    </div>
                                ) : (
                                        <select className="form-control" id="example-select" name="company" value={this.state.formFields.company} onChange={this.handleInputChange}>
                                            <option key='all' value='all'>All</option>
                                            {this.state.companiesFiltered.map((company, idx) => <option key={idx} value={company.name} >{company.name}</option>)}
                                        </select>)}

                            </dd>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-xs-12">
                            <dt><label>Select Project Types</label></dt>
                            <dd>
                                <select className="form-control" id="example-select" name="projectType" value={this.state.formFields.projectType} onChange={this.handleInputChange}>
                                    <option key='all' value='all'>All</option>
                                    {this.state.projectTypes.map((projectType, idx) => <option key={idx} value={projectType.projectType}>{projectType.projectType}</option>)}
                                </select>
                            </dd>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-xs-12">
                            <dt><label>Select Status</label></dt>
                            <dd>
                                <select className="form-control" id="example-select" name="status" value={this.state.formFields.status} onChange={this.handleInputChange}>
                                    <option key='all' value="all">All (Except Closed)</option>
                                    {this.state.statuses.map((status, idx) => <option key={idx} value={status.status}>{status.status}</option>)}
                                </select>
                            </dd>
                        </div>

                        {/*<div className="col-lg-4 col-sm-6 col-xs-12">
                            <dt><label>Project Group</label></dt>
                            <dd>
                                <select className="form-control" id="example-select" name="group" value={this.state.formFields.group ? this.state.formFields.group : ''} onChange={this.handleInputChange}>
                                    <option key='all' value='all'>All Project Groups</option>
                                    {this.state.filteredProjectGroups.map((group) => <option key={group} value={group}>{group}</option>)}
                                </select>


                            </dd>
                        </div>*/}

                        <div className="col-lg-4 col-sm-6 col-xs-12">
                            <dt><label>Assigned To</label></dt>

                            <select className="form-control" id="example-select" name="resource" value={this.state.formFields.resource} onChange={this.handleInputChange}>
                                <option key='all' value='all'>All Resources</option>
                                {this.state.resourceFullNames.map((resource, idx) => <option key={idx} value={resource.id}>{resource.name}</option>)}
                            </select>
                        </div>

                        <div className="col-lg-4 col-sm-6 col-xs-12">
                            <dt><label >Select Date Needed</label></dt>
                            <DatePicker selected={this.state.formFields.dateNeeded} onChange={this.onChange}
                                className={"form-control"} />

                        </div>

                        <div className="col-lg-4 col-sm-6 col-xs-12">
                            <dt><label>Project Priority</label></dt>

                            <select className="form-control" id="example-select" name="priority" value={this.state.formFields.priority} onChange={this.handleInputChange}>
                                <option key='all' value='all'>All Priorities</option>
                                <option key='low' value='Low'>Low</option>
                                <option key='medium' value='Medium'>Medium</option>
                                <option key='high' value='High'>High</option>
                            </select>
                        </div>

                        <div className="col-lg-4 col-sm-6 col-xs-12">
                            <dt><label>Client Contact</label></dt>

                            <select className="form-control" id="example-select" name="clientContact" value={this.state.formFields.clientContact} onChange={this.handleInputChange}>

                                <option key="all" value="all">All Contacts</option>
                                {this.state.clientContacts.map((contact) => <option key={contact.users_Id} value={contact.users_Id}>{contact.fName} {contact.lName}</option>)}
                            </select>
                        </div>

                        <div className="col-lg-4 col-sm-6 col-xs-12">
                            <dt><label>Show Only Urgent Requests</label></dt>
                            <input type="checkbox" name="workExclusive" checked={this.state.formFields.workExclusive} onChange={this.handleInputChange} />
                            </div>

                            <div className="col-lg-4 col-sm-6 col-xs-12">
                                <dt><label>Most Recent Activity First</label></dt>
                                <input type="checkbox" name="mostRecentFirst" checked={this.state.formFields.mostRecentFirst} onChange={this.handleInputChange} />
                            </div>
                        </div>
                        <div className="row">
                            <button className="btn projectDetailsSubmitBtn" onClick={this.handleSubmit}>Search Projects</button>
                            <a id="projectDetailsCancelLink" onClick={this.handleClick}>Reset Search Criteria</a>
                            
                        </div>
                    </div>
                </div>
                <div className="tableContainer container">

                </div>
                {this.state.loading ? (
                    <span><ReactLoading className='tableLoading' type={"spokes"} color={'#0c7c84'} height={100} /></span>
                ) : (

                        <div className="tableContainer container">
                            
                            {this.state.projects.length > 0 ? (
                                <div>
                                    <div className="text-right bottomMargin15">
                                        {this.state.dataSheetView ?
                                            <a id="projectDetailsCancelLink" onClick={this.toggleView}>Show Detail View</a>
                                            :
                                            <a id="projectDetailsCancelLink" onClick={this.toggleView}>Show Summary View</a>
                                        }
                                        <ExcelFile filename="Automated_Exports" element={<a id="projectDetailsCancelLink">Export Selected Projects</a>}>
                                            <ExcelSheet dataSet={this.state.exportData} name="Automated Project Manager Exports" />

                                        </ExcelFile>

                                    </div>
                                    {!this.state.dataSheetView ? (
                                        <div className="Box">
                                            <div className="Box-row Box-row--gray">
                                                <nav className="">
                                                    <nav className="row">
                                                        <div className="col-md-9 col-xs-6 mediumHeaderText">Project Details</div>
                                                        <div className="col-md-2 col-xs-3 mediumHeaderText">Status</div>
                                                        <div className="col-md-1 col-xs-3 mediumHeaderText">Priority</div>
                                                    </nav>
                                                </nav>
                                            </div>
                                            {this.state.projectsPaginated.map((project, idx) => {



                                                return (
                                                    <div className="Box-row Box-row--hover-gray" key={idx}>
                                                        <div className="row clickableRow" onClick={() => this.directToProjectDetails(project)}>
                                                            <div className="col-md-9 col-xs-3">
                                                                <p>
                                                                    <Floater
                                                                        content={
                                                                            <div>
                                                                                {project.projectsModel.projectName}
                                                                            </div>
                                                                        }
                                                                        disableHoverToClick
                                                                        event="hover"
                                                                        eventDelay={0}
                                                                        placement="top"
                                                                    >
                                                                        {project.projectsModel.projectType !== '' ? (
                                                                            <span className="strongHover">{project.projectsModel.projectType}: {project.projectsModel.projectName}</span>
                                                                        ) : (
                                                                                <span className="strongHover">{project.projectsModel.projectName}</span>
                                                                            )}

                                                                    </Floater>

                                                                    <br />
                                                                    {Globals.userInfo.userType === "Admin" &&
                                                                        <span>
                                                                            <span>{project.companiesModel.name}</span>
                                                                            <br />
                                                                        </span>
                                                                    }
                                                                    
                                                                    {Moment(project.projectsModel.dateNeededBy).format('YYYY') !== '0001' &&
                                                                        <i>Needed By: {Moment.utc(project.projectsModel.dateNeededBy).format('L')}</i>
                                                                    }
                                                                    {project.assignedToFullName &&
                                                                        <i>, Assigned To: {project.assignedToFullName}</i>
                                                                    }
                                                                    <br />
                                                                    {project.projectsModel.workExclusive === 1 &&
                                                                        <em style={{ color: 'red' }}><strong>Urgent Request</strong></em>
                                                                    }
                                                                </p>
                                                            </div>
                                                            <div className="col-md-2 col-xs-3">
                                                                <p style={{ fontSize: 'smaller' }}><strong><em>{project.statusesModel.status}</em></strong></p>
                                                                <p style={{ fontSize: 'smaller' }}>{project.statusesModel.status === 'Active - Pending Automated Action' ? project.assignedToFullName : project.clientContact}</p>
                                                            </div>
                                                            <div className="col-md-1 col-xs-3">
                                                                <p style={{ fontSize: 'smaller' }}><strong><em>{project.projectsModel.priority}</em></strong></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}

                                        </div>
                                    ) : (
                                            <Grid
                                                key={row => row.projectsModel.project_Id}
                                                columns={[
                                                    {
                                                        title: () => 'Project Name',
                                                        value: (row, { focus }) => {
                                                            return (
                                                                <Input
                                                                    value={row.projectsModel.projectName}
                                                                    focus={focus}
                                                                />
                                                            );
                                                        },
                                                        
                                                    }, {
                                                        title: () => 'Priority',
                                                        value: (row, { focus }) => {
                                                            return (
                                                                <Input
                                                                    value={row.projectsModel.priority}
                                                                    isOpen={focus}
                                                                />
                                                            );
                                                        }
                                                    }, {
                                                        title: () => 'Date Needed By',
                                                        value: (row, { focus }) => {
                                                            return (

                                                                <Input
                                                                    value={Moment.utc(row.projectsModel.dateNeededBy).format('L')}
                                                                    isOpen={focus}
                                                                />
                                                            );
                                                        }
                                                    }, {
                                                        title: () => 'View Details',
                                                        value: (row, { focus }) => {
                                                            return (
                                                                <a onClick={() => this.directToProjectDetails(row)}>View Project</a>
                                                                
                                                            );
                                                        }
                                                    }, 
                                                ]}
                                                onCellClick={row => this.directToProjectDetails(row)}
                                                rows={this.state.projects}
                                                getRowKey={row => row.projectsModel.project_Id}
                                            />
                                        )}
                                </div>
                            ) : (
                                    <p><em><strong>No projects to display</strong></em></p>
                                )}
                            {/*<div className="col-lg-3 col-sm-6 col-xs-12 marginBottom30">
                              <dt><label>Sort By</label></dt>

                              <select className="form-control" id="example-select" name="value" value={this.state.formFields.value} onChange={this.handleSortChange}>
                                  <option key='none' value=''></option>
                                  <option key='dateNeededBy' value='dateNeededBy'>Date Needed By</option>
                                  <option key='typeOfProject' value='typeOfProject'>Type of Project</option>
                                  <option key='projectStatus' value='projectStatus'>Project Status</option>
                                  <option key='projectPriority' value='projectPriority'>Project Priority</option>
                                  <option key='assignedTo' value='assignedTo'>Assigned To</option>
                                  <option key='clientContact' value='clientContact'>Client Contact</option>
                                  <option key='projectGroup' value='projectGroup'>Project Group</option>
                              </select>
                                  
                               
                              <div style={{ margin: '5px auto' }}>
                                  <input type="radio" name="isAscending" value={true} onChange={this.handleSortChange}></input>&nbsp;Ascending&nbsp;&nbsp;
                                <input type="radio" name="isAscending" value={false} onChange={this.handleSortChange}></input>&nbsp;Descending
                              </div>      
                              <button className="btn fontSize11" onClick={this.handleSort}>Sort Projects</button>
                          </div>
                        <div className="row aboveBox">
                            <div className="col-sm-7 col-xs-12"><strong>{this.state.projects.length} projects found matching your search criteria</strong></div>
                            <div className="col-sm-5 col-xs-6 alignRight"><a onClick={this.directToAddProject}>Submit New Request</a></div>
                              
                        </div>*/}

                        </div>

                    )}
                {(this.state.projects.length > 0 && !this.state.dataSheetView) ? this.createPagination() : <div style={{ marginTop: '30px' }}></div>}
            </Fragment>
        );
    }
}

export default withRouter(UserHome);