import React, { Component } from 'react';
import { withRouter } from "react-router-dom";

import Globals from '../../config/globals';
import Axios from '../../config/axios';

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Moment from 'moment';

class Backups extends Component {

    constructor(props) {
        super(props);

        this.state = {
            backupHistory: [],
            startDate: new Date(),
            endDate: new Date(),
            companies: [],
            formFields: {
                company: {}
            }
        };

        var currentIDriveLocal = null;

        this.getCompanies = this.getCompanies.bind(this);
        this.handleCompanyChange = this.handleCompanyChange.bind(this);
        this.getChangedCompanyByName = this.getChangedCompanyByName.bind(this);
        this.getCompanyHistory = this.getCompanyHistory.bind(this);
        this.formatCompanyHistory = this.formatCompanyHistory.bind(this);
        this.changeStartDate = this.changeStartDate.bind(this);
        this.changeEndDate = this.changeEndDate.bind(this);
        this.renderMessage = this.renderMessage.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        if (!Globals.userInfo.accessToBackups) {
            let path = '/your-projects';
            this.props.history.replace(path);
        }

        this.props.history.push({
            state: { page: 'your-backups' }
        });

        var date = new Date();
        date.setDate(this.state.startDate.getDate() - 7);

        this.setState({
            startDate: date
        }, () => this.getCompanyHistory(this.state.startDate, this.state.endDate));

        this.getCompanies();
    }

    getCompanies() {
        Axios.post(`/api/GetCompaniesList`
        ).then(response => {
            this.setState({
                companies: response.data
            });
        }).catch(error => {
            console.log(error);
        });
    }

    handleCompanyChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (name === 'company') {

            this.getChangedCompanyByName(value);
        };

        this.setState({ formFields: { ...this.state.formFields, [name]: value } });
    }

    getChangedCompanyByName(name) {
        let postdata = {};
        postdata.company_name = name;
        Axios.post(`/api/GetCompanyByName`, postdata
        ).then(response => {
            this.setState({
                company: response.data
            }, () => { console.log(this.state.company); this.getCompanyHistory(this.state.startDate, this.state.endDate, this.state.company); });
        }).catch(error => {
            console.log(error);
        });
    }

    getCompanyHistory(startDate, endDate, company) {
        let postdata = {};


        if (company) {
            postdata.companies_id = company.companies_Id;
        }
        else {
            postdata.companies_id = Globals.userInfo.companies_id;
        }

        //Format date string
        var yearS = startDate.getFullYear();
        var monthS = startDate.getMonth() + 1;
        var dayS = startDate.getDate();

        var yearE = endDate.getFullYear();
        var monthE = endDate.getMonth() + 1;
        var dayE = endDate.getDate();

        var dateStringStart = yearS + "-" + monthS + "-" + dayS;
        var dateStringEnd = yearE + "-" + monthE + "-" + dayE;

        postdata.dateStringStart = dateStringStart;
        postdata.dateStringEnd = dateStringEnd;

        Axios.post(`/api/GetCompanyBackups`, postdata
        ).then(response => {
            this.setState({
                backupHistory: response.data
            }, () => this.formatCompanyHistory(this.state.backupHistory));
        }).catch(error => {
            console.log(error);
        });
    }

    formatCompanyHistory(backupHistory) {
        for (var i = 0; i < backupHistory.length; i++) {
            switch (backupHistory[i].emailType) {
                case 'Cloudberry':
                    backupHistory[i].emailType = "Cloudberry Backup to Amazon S3 Cloud";
                    break;
                case 'Vice Versa':
                    backupHistory[i].emailType = "Vice Versa Backup to local network";
                    break;
                case 'IDrive Local':
                    backupHistory[i].emailType = "IDrive Backup to local appliance";
                    backupHistory[i].startDate = "";
                    backupHistory[i].dataCopied = "";
                    backupHistory[i].filesCopied = null;
                    break;
                case 'IDrive Cloud':
                    backupHistory[i].emailType = "IDrive Backup to cloud appliance";
                    backupHistory[i].startDate = "";
                    backupHistory[i].dataCopied = "";
                    backupHistory[i].filesCopied = null;
                    break;
                default:
                    break;
            }
        }

        this.setState({
            backupHistory: backupHistory
        });
    }

    changeStartDate = (date2) => {
        this.setState({
            startDate: date2
        });
    }

    changeEndDate = (date2) => {
        this.setState({
            endDate: date2
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        this.getCompanyHistory(this.state.startDate, this.state.endDate);
    }


    renderDataCopied(history) {
        if (history.emailType === 'IDrive Backup to local appliance') {
            return;
        }
        else if (history.emailType === 'IDrive Backup to cloud appliance') {
            return;
        }
        else {
            return (<div>Files Copied: <em>{history.filesCopied}</em><br />Data Copied: <em>{history.dataCopied}</em> </div>);
        }
    }

    renderMessage(item) {

        if (item.message && item.emailType === 'Cloudberry Backup to Amazon S3 Cloud') {
            return (<span>Message: <span className="colorRed"><em>{item.message}</em></span></span>);
        }

        else if (item.emailType === 'IDrive Backup to local appliance') {

            this.currentIDriveLocal = item;

            var localStrArray = item.message.split(", ");
            var formattedMessageSuccess = "";
            var formattedMessageFail = "";

            localStrArray.map((item, idx) => {
                var subArray = item.split(": ");
                var fail = subArray[1] === "Failed" ? true : false;

                if (!fail) {
                    formattedMessageSuccess += item + ", ";
                }
                else {
                    formattedMessageFail += item + ", ";
                }
            });

            formattedMessageFail = formattedMessageFail.substring(0, formattedMessageFail.length - 2);
            formattedMessageSuccess = formattedMessageSuccess.substring(0, formattedMessageSuccess.length - 2);
            if (formattedMessageFail) {
                return (<div>Message: <em><span>{formattedMessageSuccess}</span>, <span className="colorRed">{formattedMessageFail}</span></em></div>);
            }
            else {
                return (<div>Message: <em><span>{formattedMessageSuccess}</span></em></div>);
            }
            
        }
        else if (item.emailType === 'IDrive Backup to cloud appliance') {



            var cloudStrArray = item.message.split(", ");
            var formattedMessageSuccess = "";
            var formattedMessageFail = "";
            var failedArray = [];

            var localStrArray = item.message.split(", ");
            var formattedMessageSuccess = "";
            var formattedMessageFail = "";

            localStrArray.map((item, idx) => {
                var subArray = item.split(": ");
                var fail = subArray[1] === "Failed" ? true : false;

                if (!fail) {
                    formattedMessageSuccess += item + ", ";
                }
                else {
                    formattedMessageFail += item + ", ";
                }
            });
            
            //for (var i = 0; i < this.state.backupHistory.length; i++) {
                //if (this.state.backupHistory[i].emailType === 'IDrive Backup to local appliance') {
           
                    //for (var j in historyArray) {
                    //    if (cloudStrArray.indexOf(historyArray[j]) === -1) {
                    //        failedArray.push(historyArray[j]);
                    //    }
                    //    formattedMessageSuccess += historyArray[j] + ", ";
                    //}
                    //for (var h in cloudStrArray) {
                    //    if (historyArray.indexOf(cloudStrArray[h]) === -1) {
                    //        failedArray.push(cloudStrArray[h]);
                    //    }
                    //}

                    //for (var x in failedArray) {
                    //    formattedMessageFail += failedArray[x] + ", ";
                    //}
                //}
            //}

                formattedMessageFail = formattedMessageFail.substring(0, formattedMessageFail.length - 2);
                formattedMessageSuccess = formattedMessageSuccess.substring(0, formattedMessageSuccess.length - 2);

            
            this.currentIDriveLocal = null;
            console.log(this.currentIDriveLocal);
            

            if (formattedMessageFail) {
                return (<div>Message: <em><span>{formattedMessageSuccess}</span>, <span className="colorRed">{formattedMessageFail}</span></em></div>);
            }
            else {
                return (<div>Message: <em><span>{formattedMessageSuccess}</span></em></div>);
            }

            
        }
        else if (item.message && item.emailType !== 'Cloudberry Backup to Amazon S3 Cloud') {
            return (<span>Message: <em>{item.message}</em></span>);
        }
        

    }


    render() {


        return (
            <div>
                <div className="adminSearchDiv">

                    

                    <div className="row container autoMargin">
                        {Globals.userInfo.userType === "Admin" &&
                            <div className="col-lg-4 col-sm-6 col-xs-12">
                                <dt><label>Select Company</label></dt>
                                <dd>

                                    <select className="form-control" id="example-select" name="company" value={this.state.formFields.company} onChange={this.handleCompanyChange}>
                                        <option key='all' value='all'>All</option>
                                        {this.state.companies.map((company, idx) => <option key={idx} value={company.name} >{company.name}</option>)}
                                    </select>

                                </dd>
                            </div>
                        }
                        <div className="col-lg-4 col-sm-6 col-xs-12 bottomMargin15">

                            <div>
                            <label>Most Recent Backup Date</label>
                            <DatePicker
                                selected={this.state.endDate}
                                onChange={date => this.changeEndDate(date)}
                                selectsEnd
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                minDate={this.state.startDate}
                                className={"form-control bottomMargin15"}
                                />
                            </div>
                            <div>
                            <label>Oldest Backup Date</label>
                            <DatePicker
                                selected={this.state.startDate}
                                onChange={date => this.changeStartDate(date)}
                                selectsStart
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                className={"form-control bottomMargin15"}
                                />
                            </div>
                            <button className="btn projectDetailsSubmitBtn" onClick={this.handleSubmit}>Filter Date</button>
                        </div>
                    </div>
                    
                </div>
                <div className="tableContainer container bottomMargin15">
                    {this.state.backupHistory.length > 0 ? (
                        <div className="Box">
                            <div className="Box-row Box-row--gray">
                                <nav className="">
                                    <nav className="row">
                                        <div className="col-md-10 col-xs-9 mediumHeaderText">Backup History Details</div>
                                        <div className="col-md-2 col-xs-3 mediumHeaderText">Result</div>
                                    </nav>
                                </nav>
                            </div>
                            {this.state.backupHistory.map((history, idx) => {



                                return (

                                    <div className="Box-row Box-row--hover-gray" key={idx}>
                                        <div className="row clickableRow" >
                                            <div className="col-md-10 col-xs-3">
                                                <div>
                                                    <span className="strongHover">{history.emailType}</span>


                                                    <br />
                                                    <span>
                                                        {history.startDate ?
                                                            <span>
                                                                Start Date: <em>{Moment.utc(history.startDate).format('lll')}</em><br />
                                                            </span>
                                                            :
                                                            <span></span>
                                                        }
                                                        <em>End Date: {Moment.utc(history.endDate).format('lll')}</em></span>
                                                    <br />
                                                    <div>{this.renderDataCopied(history)}</div>

                                                    <div>{this.renderMessage(history)}</div>
                                                    
                                                </div>
                                            </div>
                                            <div className="col-md-2 col-xs-3">
                                                {history.backupResult === 'Warnings' ? 
                                                    <p style={{ fontSize: 'smaller', color: 'red' }}><strong><em>{history.backupResult}</em></strong></p>
                                                    :
                                                    <p style={{ fontSize: 'smaller', color: 'green' }}><strong><em>{history.backupResult}</em></strong></p>
                                                }
                                                
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}

                        </div>

                    ) : (
                            <p><em><strong>No backups to display</strong></em></p>
                        )}
                </div>
            </div>
        );
    }
}

export default withRouter(Backups);