import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import Globals from '../../config/globals';
import Axios from '../../config/axios';
import DatePicker from 'react-datepicker';
import Moment from 'moment';
import { smallBox, SmartMessageBox } from "../../common/utils/functions";
import AddProjectFileInput from './files/AddProjectFileInput';

class AddProject extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: true,
            project: {},
            companies: [],
            companiesFiltered: [],
            projectTypes: [],
            statuses: [],
            projects: [],
            projectResources: [],
            resourceFullNames: [],
            projectGroups: [],
            filteredProjectGroups: [],
            clientContacts: [],
            createdBy: {},
            updatedBy: {},
            projectContact: {},
            company: {},
            projectFiles:[],
            formFields: {
                companyName: "",
                projectType: "",
                projectResource: "",
                projectStatus: "Pending",
                projectGroup: "",
                projectName: "",
                projectContact: "",
                contactID: 0,
                dateNeededBy: "",
                projectPriority: "",
                workExclusive: false,
                createdBy: "",
                lastUpdatedBy: "",
                dateCompleted: null,
                projectDetails: "",
                createdFName: "",
                createdLName: "",
                createdDate: new Date(),
                updatedDate: new Date(),
                assignedToFullName: "",
                assignedToId: 0,
                confidentialProject: false
            }
        };

        this.goBack = this.goBack.bind(this);
        this.getCompanies = this.getCompanies.bind(this);
        this.getCompanyByName = this.getCompanyByName.bind(this);
        this.getClientContacts = this.getClientContacts.bind(this);
        this.getActiveProjectTypes = this.getActiveProjectTypes.bind(this);
        this.getProjectResources = this.getProjectResources.bind(this);
        this.getActiveStatuses = this.getActiveStatuses.bind(this);
        this.getProjectGroups = this.getProjectGroups.bind(this);
        this.onDateNeededChange = this.onDateNeededChange.bind(this);
        this.handleNotifications = this.handleNotifications.bind(this);
        this.setFormFields = this.setFormFields.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.addProject = this.addProject.bind(this);
        this.getFilteredProjectGroups = this.getFilteredProjectGroups.bind(this);

    }

    componentDidMount() {

        this.getCompanies();
        this.getActiveProjectTypes();
        this.getProjectResources();
        this.getActiveStatuses();
        this.getProjectGroups();
        this.getCompanyByName(Globals.userInfo.company_name);
    }

    addProjectFile(file) {
        var mylist = this.state.projectFiles;
        mylist.push(file);

        this.setState({
            projectFiles: mylist
        }, () => console.log(this.state.projectFiles));
    }

    goBack() {
        if (this.projectDetailsIsDirty) {

            SmartMessageBox(
                {
                    title: "All changes will be lost.",
                    content:
                        "Are you sure you would like to proceed?",
                    buttons: "[No][Yes]"
                },
                (ButtonPressed) => {
                    if (ButtonPressed === "Yes") {
                        let path = '/your-projects';
                        this.props.history.replace(path);
                    }
                    if (ButtonPressed === "No") {
                        return 0;
                    }
                }
            );
        }
        else {
            this.props.history.push({
                pathname: '/your-projects'

            });
        }

    }

    getCompanies() {
        Axios.post(`/api/GetCompaniesList`
        ).then(response => {

            this.setState({
                companies: response.data
            }, () => this.getCompanyIdList());
        }).catch(error => {
            console.log(error);
        });
    }

    getCompanyIdList() {
        let postdata = {};
        postdata.companies_id = Globals.userInfo.users_Id;

        Axios.post(`/api/GetCompanyIdList`, postdata
        ).then(response => {
            if (response.data.length < 2 && Globals.userInfo.userType !== 'Admin') {
                this.setState({
                    disabled: false
                });
            };

            this.setState({
                companiesFiltered: response.data
            });
        }).catch(error => {
            console.log(error);
        });
    }

    getCompanyByName(name) {
        let postdata = {};
        postdata.company_name = name;

        Axios.post(`/api/GetCompanyByName`, postdata
        ).then(response => {
            this.setState({
                company: response.data
            }, () => { this.getClientContacts(this.state.company.companies_Id); this.getFilteredProjectGroups(this.state.company.companies_Id); });
        }).catch(error => {
            console.log(error);
        });
    }

    getFilteredProjectGroups(id) {
        let postdata = {};
        postdata.companies_id = id;
        Axios.post(`/api/GetProjectGroupsAsClient`, postdata
        ).then(response => {
            this.setState({
                filteredProjectGroups: response.data
            });
        }).catch(error => {
            console.log(error);
        });
    }

    getClientContacts(id) {
        let postdata = {};
        postdata.companies_id = id;

        Axios.post(`/api/GetClientContacts`, postdata
        ).then(response => {
            this.setState({
                clientContacts: response.data
            });
        }).catch(error => {
            console.log(error);
        });
    }

    getActiveProjectTypes() {
        Axios.post(`/api/GetActiveProjectTypes`
        ).then(response => {
            this.setState({
                projectTypes: response.data
            });
        }).catch(error => {
            console.log(error);
        });
    }

    getProjectResources() {
        var tempList = [];
        Axios.post(`/api/GetProjectResourcesAsAdmin`
        ).then(response => {
            for (var i = 0; i < response.data.length; i++) {
                var name = response.data[i].fName + " " + response.data[i].lName;
                tempList.push(name);
            }

            this.setState({
                projectResources: response.data,
                resourceFullNames: tempList
            });

        }).catch(error => {
            console.log(error);
        });
    }

    getActiveStatuses() {
        Axios.post(`/api/GetActiveStatuses`
        ).then(response => {
            this.setState({
                statuses: response.data
            });
        }).catch(error => {
            console.log(error);
        });
    }

    getProjectGroups() {
        Axios.post(`/api/GetProjectGroupsAsAdmin`
        ).then(response => {
            this.setState({
                projectGroups: response.data
            });

        }).catch(error => {
            console.log(error);
        });
    }

    onDateNeededChange = (date2) => {
        var ffCopy = { ...this.state.formFields };
        ffCopy.dateNeededBy = date2
        this.setState({ formFields: ffCopy },
            () => {
                if (JSON.stringify(this.state.formFields) !== JSON.stringify(this.formFields)) {
                    this.projectDetailsIsDirty = true;  // dirty
                } else {
                    this.projectDetailsIsDirty = false; // pristine
                }
            });
    }

    handleNotifications(event) {
        event.preventDefault();

        const target = event.target;
        var value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        console.log(value);
        console.log(this.state.clientContacts);

        var curCont = JSON.parse(value);
        curCont.tempIsSelected = curCont.tempIsSelected ? !curCont.tempIsSelected : true;
        console.log(curCont);

        const index = this.state.clientContacts.findIndex(contact => contact.users_Id === curCont.users_Id),
            clientContacts = [...this.state.clientContacts] // important to create a copy, otherwise you'll modify state outside of setState call
        clientContacts[index] = curCont;
        this.setState({ clientContacts }, () => console.log(this.state.clientContacts));
        
    }

    setFormFields() {
        var ffCopy = { ...this.state.formFields };

        ffCopy.companyName = "";
        ffCopy.projectType = "";
        ffCopy.assignedToFullName = "";
        ffCopy.assignedToId = "";
        ffCopy.projectStatus = "Pending";
        ffCopy.projectGroup = "";
        ffCopy.projectName = "";
        ffCopy.projectContact = "";
        ffCopy.contactID = "";
        ffCopy.dateNeededBy = null;

        ffCopy.projectPriority = "";
        ffCopy.workExclusive = "";

        ffCopy.projectDetails = "";


        this.setState({
            formFields: ffCopy,
            disabled: true
        });
    }

    handleClick(event) {
        event.preventDefault();

        switch (event.target.id) {
            case 'projectDetailsCancelBtn':
                if (this.projectDetailsIsDirty) {

                    SmartMessageBox(
                        {
                            title: "You are about to lose your changes.",
                            content:
                                "Are you sure you would like to proceed?",
                            buttons: "[No][Yes]"
                        },
                        (ButtonPressed) => {
                            if (ButtonPressed === "Yes") {
                                this.projectDetailsIsDirty = false;
                                this.setFormFields();
                            }
                            if (ButtonPressed === "No") {
                                return 0;
                            }
                        }
                    );

                }

                break;
            default:
                alert('default condition hit');
                break;
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        if (name === 'companyName') {
            this.setState({
                disabled: false
            });
            this.getCompanyByName(value);
        };
        this.setState({ formFields: { ...this.state.formFields, [name]: value } },
            () => {
                console.log(this.state.formFields.confidentialProject);
                if (JSON.stringify(this.state.formFields) !== JSON.stringify(this.formFields)) {
                    this.projectDetailsIsDirty = true;  // dirty
                } else {
                    this.projectDetailsIsDirty = false; // pristine
                }
            });
    }

    addProject() {
        let postdata = {
            project: {
                projects_Id: 0,
                companies_Id: 0
            },
            statuses: {}
        };

        let usersNotified = "";
        for (var i = 0; i < this.state.clientContacts.length; i++) {
            if (this.state.clientContacts[i].tempIsSelected) {
                usersNotified += this.state.clientContacts[i].users_Id + "|";
            }
        }
        console.log(usersNotified);
        
        postdata.project.companies_Id = this.state.company.companies_Id;
        postdata.project.projectName = this.state.formFields.projectName;
        postdata.project.projectType = this.state.formFields.projectType;
        postdata.project.projectGroup = this.state.formFields.projectGroup;
        postdata.project.priority = this.state.formFields.projectPriority;
        postdata.project.workExclusive = this.state.formFields.workExclusive === true ? 1 : 0;
        postdata.project.projectDetails = this.state.formFields.projectDetails;
        postdata.project.dateNeededBy = this.state.formFields.dateNeededBy;
        postdata.project.projectContact = this.state.formFields.contactID;
        postdata.project.assignedTo = this.state.formFields.assignedToId;
        postdata.project.createdBy = Globals.userInfo.users_Id;
        postdata.project.createdOn = Moment();
        postdata.project.confidentialProject = this.state.formFields.confidentialProject === true ? 1 : 0;
        postdata.project.usersNotified = usersNotified;

        postdata.statuses.Status = this.state.formFields.projectStatus;

        postdata.suppressAllEmails = Globals.userInfo.suppressAllEmails;

        if (postdata.project.projectName === "" ||
            postdata.project.projectDetails === "" ||
            postdata.project.dateNeededBy === "") {
            smallBox({
                title: "Notice",
                content: "<i class='fa fa-clock-o'></i> <i>Please, at a minimum add a project name, date needed by and description.</i>",
                color: "red",
                iconSmall: "fa fa-times fa-2x fadeInRight animated",
                timeout: 4000
            });
        } else {
            Axios.post(`/api/AddProject`, postdata
            ).then(response => {
                if (response.data !== null) {
                    console.log(response.data);
                    for (var i = 0; i < this.state.projectFiles.length; i++) {
                        let formdata = new FormData();
                        formdata.append('file', this.state.projectFiles[i]);
                        formdata.append('addedBy', Globals.userInfo.users_Id);
                        formdata.append('projectID', response.data);


                        Axios.post(`/api/AddAttachment`, formdata
                        ).then(response => {
                            
                        }).catch(error => {
                            console.log(error);
                        });
                    }
                    

                    this.props.history.push({
                        pathname: '/your-projects',
                        state: { projectAdded: true }

                    });


                }
                else {
                    smallBox({
                        title: "Notice",
                        content: "<i class='fa fa-clock-o'></i> <i>Did not update</i>",
                        color: "red",
                        iconSmall: "fa fa-times fa-2x fadeInRight animated",
                        timeout: 4000
                    });
                }

            }).catch(error => {
                console.log(error);
            });
        }       


    }

    render() {
        return (
            <div>
                <div className="adminSearchDiv">
                    <div className="autoMargin row container ">
                        <div className="col-12 paddingBottom25">
                            <a onClick={this.goBack}>Return to Project List</a>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-xs-12">
                            <dt><label>Company</label></dt>
                            <dd>
                                {this.state.companiesFiltered.length < 2 ? (
                                    <div>
                                        {Globals.userInfo.userType === "Admin" ? (
                                            <select className="form-control" id="example-select" name="companyName" value={this.state.formFields.companyName} onChange={this.handleInputChange}>
                                                <option value="all"></option>
                                                {this.state.companies.map((company, idx) => <option key={idx} value={company.name} >{company.name}</option>)}
                                            </select>
                                        ): (
                                                <input className="form-control" name="companyName" value={Globals.userInfo.company_name} readOnly />
                                    )}
                            
                                    </div>
                                ) : (
                                        <select className="form-control" id="example-select" name="companyName" value={this.state.formFields.companyName} onChange={this.handleInputChange}>
                                            <option value="all"></option>
                                            {this.state.companiesFiltered.map((company, idx) => <option key={idx} value={company.name} >{company.name}</option>)}
                                        </select>)}


                            </dd>
                        </div>

                        <div className="col-lg-4 col-sm-6 col-xs-12">
                            <dt><label>Type of Project</label></dt>
                            <dd>
                                <select className="form-control" id="example-select" name="projectType" value={this.state.formFields.projectType} onChange={this.handleInputChange} disabled={this.state.disabled}>
                                    <option value="">Select Project Type</option>
                                    {this.state.projectTypes.map((type) => <option key={type.projectType} value={type.projectType}>{type.projectType}</option>)}
                                </select>
                            </dd>
                        </div>
                        
                        <div className="col-lg-4 col-sm-6 col-xs-12">
                            <dt><label>Project Status</label></dt>
                        {Globals.userInfo.userType === "Admin" ? (
                            <select className="form-control" id="example-select" name="projectStatus" value={this.state.formFields.projectStatus} onChange={this.handleInputChange} disabled={this.state.disabled}>
                                <option value="">Select Status</option>
                                {this.state.statuses.map((status) => <option key={status.status} value={status.status}>{status.status}</option>)}
                            </select>
                        ) : (
                            <input className="form-control" name="projectStatus" value="Pending" readOnly />
                        )}
                        </div>

                        <div className="col-lg-4 col-sm-6 col-xs-12">
                            <dt><label>Project Name</label></dt>
                            <dd>
                                <input className="form-control" name="projectName" value={this.state.formFields.projectName} onChange={this.handleInputChange} disabled={this.state.disabled}/>
                            </dd>
                        </div>

                        <div className="col-lg-4 col-sm-6 col-xs-12">
                            <dt><label>Client Contact</label></dt>
                            <dd>
                                <select className="form-control" id="example-select" name="contactID" value={this.state.formFields.contactID} onChange={this.handleInputChange} disabled={this.state.disabled}>
                                    
                                    <option value="">Select Client Contact</option>
                                    {this.state.clientContacts.map((contact) => <option key={contact.users_Id} value={contact.users_Id}>{contact.fName} {contact.lName}</option>)}
                                </select>
                            </dd>
                        </div>

                        <div className="col-lg-4 col-sm-6 col-xs-12">
                            <dt><label>Date Needed By</label></dt>
                            
                            <DatePicker selected={this.state.formFields.dateNeededBy} onChange={this.onDateNeededChange}
                                className={"form-control"} disabled={this.state.disabled} />
                        </div>

                        <div className="col-lg-4 col-sm-6 col-xs-12">
                            <dt><label>Project Priority</label></dt>
                            <dd>
                                <select className="form-control" id="example-select" name="projectPriority" value={this.state.formFields.projectPriority} onChange={this.handleInputChange} disabled={this.state.disabled}>
                                    <option value="">Select Project Priority</option>
                                    <option>Low</option>
                                    <option>Medium</option>
                                    <option>High</option>
                                </select>
                            </dd>
                        </div>

                        <div className="col-lg-4 col-sm-6 col-xs-12">
                            <dt> <label>Urgent Request?</label> <input type="checkbox" name="workExclusive" checked={this.state.formFields.workExclusive} onChange={this.handleInputChange} disabled={this.state.disabled} /> </dt>
                            {Globals.userInfo.userType !== 'Standard' &&
                                <dt className="marginTop10px"> <label>Confidential Project?</label> <input type="checkbox" name="confidentialProject" checked={this.state.formFields.confidentialProject} onChange={this.handleInputChange} /> </dt>
                            }
                        </div>

                        <div className="col-lg-4 col-sm-6 col-xs-12">
                            <dt><label>Created By</label></dt>
                            <dd>
                                <input className="form-control" value={Globals.userInfo.fName + " " + Globals.userInfo.lName + " on " + Moment().format('L')} readOnly />
                            </dd>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-xs-12">
                            <label className="textarea">Who gets notified?</label>
                            <div className="listCategoriesContainer">
                                {this.state.clientContacts.map((contact) => <option key={contact.users_Id} value={JSON.stringify(contact)} className={"listCategoriesBtn " + (contact.tempIsSelected && "activeListCategories")} onClick={this.handleNotifications}>{contact.fName} {contact.lName}</option>)}
                            </div>
                        </div>
                        <div className="col-lg-12 col-sm-12 col-xs-12">
                            <dt><label>Project Details</label></dt>
                            <dd>
                                <textarea className="form-control" rows="6" name="projectDetails" placeholder="Add Project Details" value={this.state.formFields.projectDetails} onChange={this.handleInputChange} disabled={this.state.disabled} />
                            </dd>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <AddProjectFileInput projectID={this.props.projectID} reload={this.state.reload} refreshList={this.refreshList} project={this.props.project} addProjectFile={(file) => this.addProjectFile(file)} />
                    </div>
                    <div className="col-lg-12">
                        <div className="row justify-content-end">
                            <div className="col-lg-2 col-xs-12">
                                <button className="btn projectDetailsSubmitBtn" onClick={this.addProject}>Save Changes</button>
                            </div>
                            <div className="col-lg-2 col-xs-12">
                                <button className="btn projectDetailsSubmitBtn" id="projectDetailsCancelBtn" onClick={this.handleClick}>Cancel Changes</button>
                            </div>
                            <div className="col-lg-3 col-xs-12 marginTop15px">
                                <a className="" onClick={this.goBack}>Return to Project List</a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default withRouter(AddProject);