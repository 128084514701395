import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import logo from '../img/ac-logo-color.png';
import Axios from '../config/axios';
import { smallBox } from "../common/utils/functions";
import jwt from 'jwt-simple';

class ResetPassword extends Component {

    constructor(props) {
        super(props);

        this.state = {
            linkExpired: false
        }

        this.setLinkExpired = this.setLinkExpired.bind(this);
        this.requestLink = this.requestLink.bind(this);
        this.sendEmail = this.sendEmail.bind(this);
    }

    componentDidMount() {
        if (this.props.match.params.date !== "1") {
            var secret = 'mysecret';
            var dateDecoded = jwt.decode(this.props.match.params.date, secret)
            var date = new Date(dateDecoded);

            if ((new Date().getTime() > (date.getTime() + 600000))) {
                console.log("Expired");
                this.setLinkExpired();
            }
        }
        
    }

    setLinkExpired() {
        this.setState({
            linkExpired: true
        });
    }

    requestLink(props) {
        var email = "";
        let postdata = {};
        postdata.email = props.match.params.id;
        Axios.post(`/api/DecryptEmail`, postdata
        ).then(response => {
            email = response.data;
            this.sendEmail(email);
        }).catch(error => {
            console.log(error);
        });
        
        smallBox({
            title: "Notice",
            content: "<i class='fa fa-clock-o'></i> <i>An email to reset your password has been sent.</i>",
            color: "#659265",
            iconSmall: "fa fa-check fa-2x fadeInRight animated",
            timeout: 4000
        });
        this.props.history.push({
            pathname: '/'
        });
    }

    sendEmail(username) {

        var date = new Date();
        var secret = 'mysecret';

        let postdata = {};
        postdata.email = username;
        postdata.password = jwt.encode(date, secret);
        console.log(jwt.decode(postdata.password, secret));
        Axios.post(`/api/SendUserEmail`, postdata
        ).then(response => {
        }).catch(error => {
            console.log(error);
        });
    }

    handleSubmit(props) {
        var newpass = document.getElementById("newpass");
        var newpass2 = document.getElementById("newpass2");

        if (newpass.value !== newpass2.value) {
            
            smallBox({
                title: "Notice",
                content: "<i class='fa fa-clock-o'></i> <i>Passwords do not match. Please try again.</i>",
                color: "#C46A69",
                iconSmall: "fa fa-times fa-2x fadeInRight animated",
                timeout: 4000
            });
        }
        else {
            let postdata = {};
            postdata.password = newpass.value;
            postdata.email = props.match.params.id;

            Axios.post(`/api/ResetUserPassword`, postdata
            ).then(response => {
                
            }).catch(error => {
                console.log(error);
            });

            let path = '/login';
            this.props.history.replace({ pathname: path, state: { passwordReset: true } });
        }
    }

    render() {
        const expiredLink = this.state.linkExpired;
        return (
            
            <div className="login-container">
                {expiredLink ? (
                    <div>This link has expired. Please
                        <a onClick={() => this.requestLink(this.props)}> click here </a>
                        to request a new password reset link.</div>
                ) : (
<div className="full-height">
                    <div className="top-header bg-dark">Automated Project Manager - Change Password</div>
                    <div className="row h-75vh">
                        <div className="left-panel-login">
                                    <img src={logo} className="width100" alt="Automated Concepts, LLC" />
                        </div>
                        <div className="divider" style={{ border: '1px solid rgb(224, 224, 224)', marginTop: '40px', marginBottom: '40px' }}></div>
                        <div className="right-panel-login col">
                            <form>
                                <div className="text-center right-contents">
                                    <div className="full-width left-align">
                                        <span>Enter your new password</span>
                                    </div>
                                    <input id="newpass" type="password" className="form-control mb-4" />

                                    <div className="full-width left-align">
                                        <span>Confirm new password</span>
                                    </div>
                                    <input id="newpass2" type="password" className="form-control mb-4" />
                                </div>
                                <div className="btnRightContainer">
                                    <button onClick={() => this.handleSubmit(this.props)} className="btn" type="submit">Reset Password</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                )}
                
            </div>
        );
    }
}

export default withRouter(ResetPassword);