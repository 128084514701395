import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import QRCode from 'qrcode.react';
import logo from '../img/ac-logo-color.png';
import Axios from '../config/axios';
import Globals from '../config/globals';
import { smallBox } from "../common/utils/functions";
import { authenticator } from 'otplib';
import cookie from 'react-cookies';

class TFASetup extends Component {

    constructor(props) {
        super(props);

        this.state = {
            keyuri: '',
            code: '',
            formFields: {
                tfaInput: '',
                trustdevice: false
            }

        };
        //https://www.npmjs.com/package/otplib#demo-and-documentation
        authenticator.options = {
            step: 30,  // default = 30
            window: 10 // default = 0
        };

        this.goLogin = this.goLogin.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.verify2FA = this.verify2FA.bind(this);
    }

    componentDidMount() {
        let postdata = {};
        postdata.email = this.props.userid;

        Axios.post(`/api/GetOtpAuth`, postdata
        ).then(response => {
            //const keyuri = response.data;
            //this.setState({ keyuri });

            //console.log(response.data);

            const keyuri = response.data.keyuri;
            const code = response.data.code;

            console.log(keyuri);
            console.log(code);

            this.setState({ keyuri, code });

        }).catch(error => {
            console.log("Here: " + error);
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        // added callback function after state.formFields has been updated to invoke a callback to parent
        // component to set form isDirty variable.
        this.setState({ formFields: { ...this.state.formFields, [name]: value } });
    }

    verify2FA = async (event) => {
        event.preventDefault();

        let validCode = false;

        let postdata = {};
        postdata.email = this.props.userid;
        postdata.password = this.state.formFields.tfaInput;

        await Axios.post(`/api/Verify2FA`, postdata
        ).then(response => {
            validCode = response.data;
        }).catch(error => {
            console.log(error);
        });

        if (validCode) {
            let postdata = {};
            postdata.email = this.props.userid;
            Axios.post('/api/SetTFAEnabled', postdata
            ).then(response => {
                //const results = response.data;
            }).catch(error => {
                console.log(error);
            });

            this.props.validateUser();
        }
        else {
            smallBox({
                title: "Notice",
                content: "<i class='fa fa-clock-o'></i> <i>Invalid Code.</i>",
                color: "#C46A69",
                iconSmall: "fa fa-times fa-2x fadeInRight animated",
                timeout: 4000
            });
        }
    }

    goLogin() {
        this.props.goLogin();
    }


    render() {
        const secret = authenticator.encode(this.props.userid.toString() + 'APM');
        const service = 'Automated Project Manager';
        const otpauth = authenticator.keyuri(this.props.userid, service, secret);
        //console.log(secret);
        return (
                <div className="full-height">
                    <div className="top-header bg-dark">Configure Authenticator App</div>
                    <div className="row">
                        
                            <form id="login-form" className="smart-form client-form" autoComplete="off">
                                

                                <fieldset className="width75Center">

                                    <section >
                                        <p>To use an authenticator app, go through the following steps: </p><br />
                                        <ol className="bottomMargin15">
                                            <li>Download a two-factor authenticator app like Microsoft Authenticator for Windows Phone, Android
                                    and iOS or Google Authenticator for Android and iOS.
                            </li>
                                    <li>
                                        Scan the QR Code into your two factor authenticator app
                                <div className="text-center">
                                            <QRCode value={this.state.keyuri} />
                                            <div>OR</div>
                                        </div>

                                        <div>Enter the human readable code provided below:</div>
                                        <strong>{this.state.code}</strong>

                                    </li>
                                            <li>Once you have scanned the QR Code above, your two factor authenticator app will provide you with a
                                                unique code. Enter the code in the confirmation box below.
                                           
                                </li>
                                        </ol>

                                        <input type="hidden" value="" />
                                        <label className="label">Enter the security code from the authenticator app on your mobile device</label>
                                        <label className="input">
                                            <i className="icon-append fa fa-mobile" />
                                            <input autoFocus className="input" name="tfaInput" onChange={this.handleInputChange} value={this.state.formFields.tfaInput} />
                                            <b className="tooltip tooltip-top-right"><i className="fa fa-user txt-color-teal" /> 6-digit code</b>
                                        </label>
                                    </section>



                                    <section style={{ textAlign: "left" }}>
                                        <label className="">
                                    <input type="checkbox" name="trustdevice" id="trustdevice" value={this.state.formFields.trustdevice} onChange={this.handleInputChange} />
                                            <i /> Trust this device. Security code will not be requested any more on this device.
                                                                </label>
                                    </section>


                                </fieldset>
                                <footer>
                                    <div className="row float-right">
                                        <button onClick={(e) => { this.verify2FA(e); }} className="btn btn-primary">
                                            Submit
                                                </button>
                                    </div>
                                    <div className="row goBackLogin">
                                        <a onClick={this.goLogin}><i className="fa fa-arrow-left"></i> Go back to login</a>
                                    </div>
                                    <br />
                                    {this.state.validUserFound === false &&
                                        <div>
                                            <span style={{ color: 'red' }}><h5>Invalid User Credentials</h5></span>
                                        </div>
                                    }
                                </footer>
                            </form>
                       
                    </div>
                </div>
            
        );
    }

}

export default withRouter(TFASetup);