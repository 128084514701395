import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import logo from '../img/ac-logo-color.png';
import Axios from '../config/axios';
import Globals from '../config/globals';
import { smallBox } from "../common/utils/functions";
import cookie, { setCookie, getCookie} from 'react-cookies';
import TFALogin from '../components/TFALogin';
import TFASetup from '../components/TFASetup';

class Login extends Component {
    static displayName = Login.name;

    constructor(props) {
        super(props);

        this.state = {
            validUserFound: true,
            companyName: "",
            showTwoFactor: false,
            showTwoFactorSetUp: false,
            noShowTwoFactor: true,
            authenticatedUser: {}
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.getUserByEmail = this.getUserByEmail.bind(this);
        this.validateUser = this.validateUser.bind(this);
        this.goLogin = this.goLogin.bind(this);
    }

    componentDidMount() {
        this.props.history.push({
            state: { page: 'login' }
        });
        if (this.props.location.state) {
            if (this.props.location.state.passwordReset) {
                smallBox({
                    title: "Notice",
                    content: "<i class='fa fa-clock-o'></i> <i>Your password has been reset.</i>",
                    color: "#659265",
                    iconSmall: "fa fa-check fa-2x fadeInRight animated",
                    timeout: 4000
                });
            }
        }
    }

    handleSubmit = async (event) => {

        event.preventDefault();

        // ***** Capture form fields to variables *****
        var myuserid = document.getElementById("userid");
        var mypwd = document.getElementById("password");

        // ***** If form not valid, return *****
        if (myuserid.value === null || myuserid.value === "") {
            myuserid.focus();
            return;
        }
        if (mypwd.value === null || mypwd.value === "") {
            mypwd.focus();
            return;
        }

        // ***** Validate user, LDAP if indicated, otherwise against db table *****
        let bearer = "";

        let postdata = {};
        postdata.email = myuserid.value;
        postdata.password = mypwd.value;

        await Axios.post(`/validateuser`, postdata
        ).then(response => {
            bearer = response.data;
        }).catch(error => {
            console.log(error);
        });
        //console.log(bearer);
        // ***** If no limited scope bearer token returned, the user credentials were invalid *****
        if (bearer === "") {

            this.setState({ validUserFound: false });

            smallBox({
                title: "Notice",
                content: "<i class='fa fa-clock-o'></i> <i>Invalid User Credentials.</i>",
                color: "#C46A69",
                iconSmall: "fa fa-times fa-2x fadeInRight animated",
                timeout: 4000
            });

            return;
        } else {
            // set limited scope bearer token where Axios knows where to find it
            let userInfo = Globals.userInfo;
            userInfo.bearer = bearer;
            Globals.userInfo = userInfo;

            console.log(Globals.userInfo);
        }

        // ***** Determine if this user has TFA enabled, this is used to determine 2FA setup vs 2FA code entry screen *****
        let tfaEnabled = false;

        postdata = {};
        postdata.email = myuserid.value;
        await Axios.post(`/api/GetTFAEnabled`, postdata
        ).then(response => {
            tfaEnabled = response.data;
        }).catch(error => {
            console.log("here");
            console.log(error);
        });

        //2FA
        if (cookie.load('bypass2FA' + myuserid.value)) {
            this.setState({
                validUserFound: true,
                userid: myuserid.value,
                password: mypwd.value
            }, () => this.validateUser());
        } else {
            if (tfaEnabled === true) {
                this.setState({
                    showTwoFactor: true,
                    noShowTwoFactor: false,
                    showTwoFactorSetUp: false,
                    //authenticatedUser: myauthenticateduser,
                    validUserFound: true,
                    userid: myuserid.value,
                    password: mypwd.value
                });
            } else {
                this.setState({
                    showTwoFactorSetUp: true,
                    noShowTwoFactor: false,
                    showTwoFactor: false,
                    //authenticatedUser: myauthenticateduser,
                    validUserFound: true,
                    userid: myuserid.value,
                    password: mypwd.value
                });
            }
        }
    }

    handleClick(event) {
        event.preventDefault();

        switch (event.target.id) {
            case 'forgotPasswordBtn':
                this.props.history.push({
                    pathname: '/forgot-password'
                });
                break;
            default:
                break;
        }
    }

    getUserByEmail(email) {
        let postdata = {};

        postdata.email = email;
        Axios.post(`/api/GetUserByEmail`, postdata
        ).then(response => {
            const user = response.data;
            if (user === null || user === "") {
                smallBox({
                    title: "Notice",
                    content: "<i class='fa fa-clock-o'></i> <i>Username not found. Please try again.</i>",
                    color: "#C46A69",
                    iconSmall: "fa fa-times fa-2x fadeInRight animated",
                    timeout: 4000
                });
            }
            else {

                //2FA
                if (cookie.load('bypass2FA' + user.users_Id)) {
                    this.validateUser(user);
                } else {
                    this.goTwoFactor(user);
                }
            }
            
            }).catch(error => {
                
            console.log(error);
        });
    }

    goTwoFactor(myauthenticateduser) {
        let mypwd = document.getElementById("password").value;

            if (myauthenticateduser.password !== mypwd) {
                this.setState({ validUserFound: false });

                smallBox({
                    title: "Notice",
                    content: "<i class='fa fa-clock-o'></i> <i>Invalid User Credentials.</i>",
                    color: "#C46A69",
                    iconSmall: "fa fa-times fa-2x fadeInRight animated",
                    timeout: 4000
                });

                return;
            }
            if (myauthenticateduser.tfaEnabled === true) {
                this.setState({
                    showTwoFactor: true,
                    noShowTwoFactor: false,
                    showTwoFactorSetUp: false,
                    authenticatedUser: myauthenticateduser,
                    validUserFound: true
                });
            } else {
                this.setState({
                    showTwoFactorSetUp: true,
                    noShowTwoFactor: false,
                    showTwoFactor: false,
                    authenticatedUser: myauthenticateduser,
                    validUserFound: true
                });
            }
        
    }


    validateUser = async () => {

        // at this point, 2FA successful, or bypassed by a cookie

        let bearer = "";

        let pdata = {};
        pdata.email = this.state.userid;
        await Axios.post(`/token`, pdata
        ).then(response => {
            bearer = response.data;
            let userInfo = Globals.userInfo;
            userInfo.bearer = bearer;
            Globals.userInfo = userInfo;
        }).catch(error => {
            console.log(error);
        });

        let myauthenticateduser = {};

        let postdata = {};
        postdata.email = this.state.userid;
        await Axios.post(`/api/GetUserByEmail`, postdata
        ).then(response => {
            myauthenticateduser = response.data;
            let postdata = {};
            postdata.email = myauthenticateduser.email;

            let userInfo = myauthenticateduser;
            var myem = myauthenticateduser.email;
            userInfo.email = myem;
            userInfo.fName = myauthenticateduser.fName;
            userInfo.lName = myauthenticateduser.lName;
            userInfo.userType = myauthenticateduser.userType;
            userInfo.companies_id = myauthenticateduser.companies_Id;
            userInfo.users_Id = myauthenticateduser.users_Id;
            userInfo.company_name = myauthenticateduser.companyName;
            userInfo.isLoggedIn = true;
            userInfo.bearer = bearer;
            userInfo.suppressEmails = myauthenticateduser.suppressEmails;
            userInfo.accessToProjects = myauthenticateduser.accessToProjects;
            window.sessionStorage.setItem("userInfo", JSON.stringify(userInfo));

            const timeInSeconds = 3600;
            const expires = new Date();
            expires.setDate(Date.now() + timeInSeconds * 60 * 60 * 24 * 14);

            cookie.save('userInfo', myauthenticateduser.email, { path: '/', expires, maxAge: timeInSeconds });

            //if logging in from external link from email
            if (Globals.tempProjID != 0) {

                let postdata = {};
                postdata.id = Globals.tempProjID;

                Axios.post(`/api/GetProjectByID`, postdata
                ).then(response => {
                    Globals.projectData = response.data;

                    this.props.history.push({
                        pathname: '/project-details',
                        state: { project: response.data }
                    });
                }).catch(error => {
                    console.log(error);
                });


            }
            //this.setState({ isLoaded: true });
            let path = '/your-projects';
            this.props.history.push({
                pathname: path,
                state: { companyName: this.state.companyName }
            });

        }).catch(error => {

            console.log(error);
        });

    }

    //validateUser(myauthenticateduser) {


    //    let postdata = {};
    //    console.log(myauthenticateduser);
    //    postdata.email = myauthenticateduser.email;
        
    //    console.log(Globals.userInfo);
    //    let userInfo = myauthenticateduser;
    //    console.log(userInfo);
    //    var myem = myauthenticateduser.email;
    //    userInfo.email = myem;
    //    userInfo.fName = myauthenticateduser.fName;
    //    userInfo.lName = myauthenticateduser.lName;
    //    userInfo.userType = myauthenticateduser.userType;
    //    userInfo.companies_id = myauthenticateduser.companies_Id;
    //    userInfo.users_Id = myauthenticateduser.users_Id;
    //    userInfo.company_name = myauthenticateduser.companyName;
    //    userInfo.isLoggedIn = true;
    //    userInfo.suppressEmails = myauthenticateduser.suppressEmails;
    //    userInfo.accessToProjects = myauthenticateduser.accessToProjects;
    //    window.sessionStorage.setItem("userInfo", JSON.stringify(userInfo));

    //    const timeInSeconds = 3600;
    //    const expires = new Date();
    //    expires.setDate(Date.now() + timeInSeconds * 60 * 60 * 24 * 14);

    //    cookie.save('userInfo', myauthenticateduser.email, { path: '/', expires, maxAge: timeInSeconds });

    //    //if logging in from external link from email
    //    if (Globals.tempProjID != 0) {

    //        let postdata = {};
    //        postdata.id = Globals.tempProjID;

    //        Axios.post(`/api/GetProjectByID`, postdata
    //        ).then(response => {
    //            Globals.projectData = response.data;

    //            this.props.history.push({
    //                pathname: '/project-details',
    //                state: { project: response.data }
    //            });
    //        }).catch(error => {
    //            console.log(error);
    //        });

            
    //    }
    //    //this.setState({ isLoaded: true });
    //    let path = '/your-projects';
    //    this.props.history.push({
    //        pathname: path,
    //        state: { companyName: this.state.companyName }
    //    });

    //}

    goLogin() {
        this.setState({
            showTwoFactor: false,
            showTwoFactorSetUp: false,
            noShowTwoFactor: true
        });
    }


    render() {
        const showTwoFactor = this.state.showTwoFactor;
        const showTwoFactorSetUp = this.state.showTwoFactorSetUp;
        const noShowTwoFactor = this.state.noShowTwoFactor;
        
        return (
            <div className="login-container">
                {showTwoFactor &&
                    <TFALogin validateUser={this.validateUser} goLogin={this.goLogin} user={this.state.authenticatedUser} userid={this.state.userid}/>
                }
                {showTwoFactorSetUp &&
                    <TFASetup validateUser={this.validateUser} goLogin={this.goLogin} user={this.state.authenticatedUser} userid={this.state.userid} />
                }
                {noShowTwoFactor &&
                    <div className="full-height">
                        <div className="top-header bg-dark">Automated Concepts Client Portal - Login</div>
                        <div className="row">
                            <div className="left-panel-login">
                                <img src={logo} className="width100" alt="Automated Concepts, LLC" />
                            </div>
                            <div className="divider" style={{ border: '1px solid rgb(224, 224, 224)', marginTop: '40px', marginBottom: '40px' }}></div>
                            <div className="right-panel-login col">

                                <form>
                                    <div className="text-center right-contents">

                                        <div className="full-width left-align">
                                            <span>Username</span>
                                        </div>
                                        <input type="email" id="userid" className="form-control mb-4" />

                                        <div className="full-width left-align">
                                            <span>Password</span>
                                        {/*<a id="forgotPasswordBtn" className="right-float" onClick={this.handleClick}>Forgot password?</a>*/}
                                        </div>
                                        <input type="password" id="password" className="form-control mb-4" />
                                    </div>
                                    <div className="btnRightContainer">
                                        <button onClick={this.handleSubmit} className="btn" type="submit">LOG IN</button>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default withRouter(Login);