import { Editor } from 'slate-react'
import { Value } from 'slate'
import { withRouter } from "react-router-dom"
import React, { Component } from 'react'
import _ from 'lodash'

import initialValue from './value.json'
//import users from './users.json'
import Suggestions from './Suggestions'

import { isKeyHotkey } from 'is-hotkey'
import { Button, Icon, Toolbar } from '../components';

import Html from 'slate-html-serializer'

import Globals from '../../../config/globals';
import Axios from '../../../config/axios';

import { smallBox, SmartMessageBox } from "../../../common/utils/functions";

const USER_MENTION_NODE_TYPE = 'userMention'

var users;

//Get users to tag from database and store to users variable
async function getUsers(companyID) {
    console.log(companyID);
    let postdata = {};
    postdata.companies_id = companyID;
    var tempList = [];
        Axios.post(`/api/GetClientContactsAndAdmins`, postdata
        ).then(response => {
            tempList = response.data;
            for (var i = 0; i < tempList.length; i++) {
                tempList[i].username = tempList[i].fName + " " + tempList[i].lName;
                tempList[i].id = tempList[i].users_Id;
            }
            users = tempList;
            console.log(users);
            })
            .catch(error => {
            console.log(error);
            });
        
        return await tempList;
}

//getUsers();

/**
 * The annotation type that the menu will position itself against. The
 * "context" is just the current text after the @ symbol.
 * @type {String}
 */

const CONTEXT_ANNOTATION_TYPE = 'mentionContext'

/**
 * Get a unique key for the search highlight annotations.
 *
 * @return {String}
 */

let n = 0

function getMentionKey() {
    return `highlight_${n++}`
}

const schema = {
    inlines: {
        [USER_MENTION_NODE_TYPE]: {
            // It's important that we mark the mentions as void nodes so that users
            // can't edit the text of the mention.
            isVoid: true,
        },
    },
}

/**
 * The regex to use to find the searchQuery.
 *
 * @type {RegExp}
 */

const CAPTURE_REGEX = /@(\S*)$/



const BLOCK_TAGS = {
    blockquote: 'quote',
    p: 'paragraph',
    pre: 'code',
}

// Add a dictionary of mark tags.
const MARK_TAGS = {
    em: 'italic',
    strong: 'bold',
    u: 'underline',
}

const rules = [
    {
        deserialize(el, next) {
            const type = BLOCK_TAGS[el.tagName.toLowerCase()]
            if (type) {
                return {
                    object: 'block',
                    type: type,
                    data: {
                        className: el.getAttribute('class'),
                    },
                    nodes: next(el.childNodes),
                }
            }
        },
        serialize(obj, children) {
            if (obj.object === 'block') {
                switch (obj.type) {
                    case 'code':
                        return (
                            <pre>
                                <code>{children}</code>
                            </pre>
                        )
                    case 'paragraph':
                        return <p className={obj.data.get('className')}>{children}</p>
                    case 'userMention':
                        return <p className={obj.data.get('className')}>{children}</p>
                    case 'quote':
                        return <blockquote>{children}</blockquote>
                    case 'block-quote':
                        return <blockquote>{children}</blockquote>
                    case 'bulleted-list':
                        return <ul>{children}</ul>
                    case 'heading-one':
                        return <h1>{children}</h1>
                    case 'heading-two':
                        return <h2>{children}</h2>
                    case 'list-item':
                        return <li>{children}</li>
                    case 'numbered-list':
                        return <ol>{children}</ol>
                    default:
                        return
                }
            }
        },
    },
    // Add a new rule that handles marks...
    {
        deserialize(el, next) {
            const type = MARK_TAGS[el.tagName.toLowerCase()]
            if (type) {
                return {
                    object: 'mark',
                    type: type,
                    nodes: next(el.childNodes),
                }
            }
        },
        serialize(obj, children) {
            if (obj.object === 'mark') {
                switch (obj.type) {
                    case 'bold':
                        return <strong>{children}</strong>
                    case 'italic':
                        return <em>{children}</em>
                    case 'underlined':
                        return <u>{children}</u>
                    case 'userMention':
                        return <p className={obj.data.get('className')}>{children}</p>
                    case 'code':
                        return (
                                <code>{children}</code>
                        )
                    case 'block-quote':
                        return <blockquote>{children}</blockquote>
                    case 'bulleted-list':
                        return <ul>{children}</ul>
                    case 'heading-one':
                        return <h1>{children}</h1>
                    case 'heading-two':
                        return <h2>{children}</h2>
                    case 'list-item':
                        return <li>{children}</li>
                    case 'numbered-list':
                        return <ol>{children}</ol>
                    default:
                        return
                }
            }
        },
    },

    {
        deserialize(el, next) {
            const type = MARK_TAGS[el.tagName.toLowerCase()]
            if (type) {
                return {
                    object: 'inline',
                    type: type,
                    nodes: next(el.childNodes),
                }
            }
        },
        serialize(obj, children) {
            if (obj.object === 'inline') {
                switch (obj.type) {
                    case 'userMention':
                        return <strong className="strongMention">{children}</strong>
                    default:
                        return
                }
            }
        },
    }
]

const html = new Html({ rules })

/**
 * Get get the potential mention input.
 *
 * @type {Value}
 */

function getInput(value) {
    // In some cases, like if the node that was selected gets deleted,
    // `startText` can be null.
    if (!value.startText) {
        return null
    }

    const startOffset = value.selection.start.offset
    const textBefore = value.startText.text.slice(0, startOffset)
    const result = CAPTURE_REGEX.exec(textBefore)

    return result == null ? null : result[1]
}

/**
 * Define the default node type.
 *
 * @type {String}
 */

const DEFAULT_NODE = 'paragraph'

/**
 * Define hotkey matchers.
 *
 * @type {Function}
 */

const isBoldHotkey = isKeyHotkey('mod+b')
const isItalicHotkey = isKeyHotkey('mod+i')
const isUnderlinedHotkey = isKeyHotkey('mod+u')
const isCodeHotkey = isKeyHotkey('mod+`')

class RichTextExample extends Component {
    /**
     * Deserialize the initial editor value.
     *
     * @type {Object}
     */

    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.sendText = this.sendText.bind(this);
        this.sendActivityUpdate = this.sendActivityUpdate.bind(this);

        console.log(this.props.project);
    }

    componentDidMount() {
        console.log(this.props.project);
        getUsers(this.props.project.projectsModel.companies_Id);
        
    }

    state = {
        users: [],
        value: Value.fromJSON(initialValue),
        formFields: {
            inputID: Globals.userInfo.users_Id,
            inputName: Globals.userInfo.fName + " " + Globals.userInfo.lName,
            createdDate: new Date(),
            updatedDate: null,
            activity: "",
            projectsID: this.props.location.state.project.projectsModel.projects_Id
        },
        taggedUsers: []
    }

    hasMark = type => {
        const { value } = this.state
        return value.activeMarks.some(mark => mark.type === type)
    }

    hasBlock = type => {
        const { value } = this.state
        return value.blocks.some(node => node.type === type)
    }

    

    ref = editor => {
        this.editor = editor;

    }

    //editorRef = React.createRef()
    editorRef = editor => {
        this.editor = editor;
        this.editorRef = React.createRef();
    }

    handleSubmit() {
        let postdata = {
            activity: {}
        };

        postdata.activity.inputID = this.state.formFields.inputID;
        postdata.activity.inputName = this.state.formFields.inputName;
        postdata.activity.createdDate = new Date();
        postdata.activity.activity = html.serialize(this.state.value);
        postdata.activity.projectsID = this.state.formFields.projectsID;
        if (postdata.activity.activity === '<p></p>') {
            smallBox({
                title: "Notice",
                content: "<i class='fa fa-clock-o'></i> <i>No Change</i>",
                color: "red",
                iconSmall: "fa fa-check fa-2x fadeInRight animated",
                timeout: 4000
            });
        }
        else {
            Axios.post(`/api/AddActivity`, postdata
            ).then(response => {
                this.setState({
                    value: Value.fromJSON(initialValue),
                    formFields: { ...this.state.formFields, createdDate: new Date() }
                }, () => {
                    this.props.onRequestUpdate();
                    this.sendText(postdata.activity.activity);
                    this.sendActivityUpdate(postdata.activity.activity);
                })

            }).catch(error => {
                console.log(error);
            });
        }
        
        
    }

    sendText(content) {
        if (this.state.taggedUsers.length > 0) {
            let postdata = {
                activity: {}
            };
            postdata.mentionsList = this.state.taggedUsers;
            postdata.activity.inputName = this.state.formFields.inputName;
            postdata.project = this.props.project.projectsModel;
            postdata.activity.createdDate = new Date();
            postdata.activity.activity = content;
            postdata.activity.projectsID = this.state.formFields.projectsID;
            postdata.suppressAllEmails = Globals.userInfo.suppressAllEmails;
            Axios.post(`/api/SendMentionMessage`, postdata
            ).then(response => {

            }).catch(error => {
                console.log(error);
                });
            
        }
        else {
            console.log("Didn't send");
        }
    }

    sendActivityUpdate(content) {
        let postdata = {
            project: {},
            activity: {}
        };

        postdata.project = this.props.project.projectsModel;
        postdata.activity.inputName = this.state.formFields.inputName;
        postdata.activity.createdDate = new Date();
        postdata.activity.activity = content;
        postdata.suppressAllEmails = Globals.userInfo.suppressAllEmails;
        if (content === '<p></p>') {
            smallBox({
                title: "Notice",
                content: "<i class='fa fa-clock-o'></i> <i>No Change</i>",
                color: "red",
                iconSmall: "fa fa-check fa-2x fadeInRight animated",
                timeout: 4000
            });
        } else {
            Axios.post(`/api/SendActivityUpdate`, postdata
            ).then(response => {

            }).catch(error => {
                console.log(error);
            });
        }
        
    }
    
    render() {
        
        return (
            
            <div>
            <div className="rich-editor">
                <Toolbar>
                    {this.renderMarkButton('bold', 'format_bold')}
                    {this.renderMarkButton('italic', 'format_italic')}
                    {this.renderMarkButton('underlined', 'format_underlined')}
                        {this.renderMarkButton('code', 'code')}
                        {this.renderBlockButton('block-quote', 'format_quote')}
                        {/*{this.renderBlockButton('heading-one', 'looks_one')}
                    {this.renderBlockButton('heading-two', 'looks_two')}
                    
                    {this.renderBlockButton('numbered-list', 'format_list_numbered')}
                    {this.renderBlockButton('bulleted-list', 'format_list_bulleted')}*/}
                </Toolbar>
                <Editor
                    spellCheck
                    autoFocus
                    placeholder="Enter some rich text..."
                    ref={this.editorRef}
                    value={this.state.value}
                    onChange={this.onChange}
                    onKeyDown={this.onKeyDown}
                    renderBlock={this.renderBlock}
                    renderMark={this.renderMark}
                    renderInline={this.renderInline}
                    renderAnnotation={this.renderAnnotation}
                    schema={schema}
                    className="text-area"
                />
                <Suggestions
                    anchor=".mention-context"
                    users={this.state.users}
                    onSelect={this.insertMention}
                    />
                    <button className="btn btn-primary floatRight" onClick={this.handleSubmit}>Add Activity</button>
            </div>

                
            </div>
        )
    }

    renderAnnotation(props, editor, next) {
        if (props.annotation.type === CONTEXT_ANNOTATION_TYPE) {
            return (
                // Adding the className here is important so that the `Suggestions`
                // component can find an anchor.
                <span {...props.attributes} className="mention-context">
                    {props.children}
                </span>
            )
        }

        return next()
    }

    renderInline(props, editor, next) {
        const { attributes, node } = props

        if (node.type === USER_MENTION_NODE_TYPE) {
            // This is where you could turn the mention into a link to the user's
            // profile or something.
            return <b {...attributes}>{props.node.text}</b>
        }

        return next()
    }

    insertMention = user => {
        const value = this.state.value
        const inputValue = getInput(value)
        const editor = this.editorRef.current
        let taggedCopy = this.state.taggedUsers;
        taggedCopy.push(user);
        
        this.state.taggedUsers = taggedCopy;
        
        //var tUCopy = this.state.taggedUsers;
        //tUCopy.push(user);
        //this.setState({
        //    taggedUsers: tUCopy
        //}, console.log(this.state.taggedUsers));

        // Delete the captured value, including the `@` symbol
        editor.deleteBackward(inputValue.length + 1)

        const selectedRange = editor.value.selection
        

        editor
            .insertText(' ')
            .insertInlineAtRange(selectedRange, {
                data: {
                    userId: user.id,
                    username: user.username,
                },
                nodes: [
                    {
                        object: 'text',
                        leaves: [
                            {
                                text: `@${user.username}`,
                            },
                        ],
                    },
                ],
                type: USER_MENTION_NODE_TYPE,
            })
            .focus()

        
    }


    renderMarkButton = (type, icon) => {
        const isActive = this.hasMark(type)

        return (
            <Button
                active={isActive}
                onMouseDown={event => this.onClickMark(event, type)}
            >
                <Icon>{icon}</Icon>
            </Button>
        )
    }


    renderBlockButton = (type, icon) => {
        let isActive = this.hasBlock(type)

        if (['numbered-list', 'bulleted-list'].includes(type)) {
            const { value: { document, blocks } } = this.state

            if (blocks.size > 0) {
                const parent = document.getParent(blocks.first().key)
                isActive = this.hasBlock('list-item') && parent && parent.type === type
            }
        }

        return (
            <Button
                active={isActive}
                onMouseDown={event => this.onClickBlock(event, type)}
            >
                <Icon>{icon}</Icon>
            </Button>
        )
    }

    /**
     * Render a Slate block.
     *
     * @param {Object} props
     * @return {Element}
     */

    renderBlock = (props, editor, next) => {
        const { attributes, children, node } = props

        switch (node.type) {
            case 'block-quote':
                return <blockquote {...attributes}>{children}</blockquote>
            case 'userMention':
                return <p {...attributes}>{children}</p>
            case 'bulleted-list':
                return <ul {...attributes}>{children}</ul>
            case 'heading-one':
                return <h1 {...attributes}>{children}</h1>
            case 'heading-two':
                return <h2 {...attributes}>{children}</h2>
            case 'list-item':
                return <li {...attributes}>{children}</li>
            case 'numbered-list':
                return <ol {...attributes}>{children}</ol>
            default:
                return next()
        }
    }

    /**
     * Render a Slate mark.
     *
     * @param {Object} props
     * @return {Element}
     */

    renderMark = (props, editor, next) => {
        const { children, mark, attributes } = props

        switch (mark.type) {
            case 'bold':
                return <strong {...attributes}>{children}</strong>
            case 'userMention':
                return <p {...attributes}>{children}</p>
            case 'code':
                return <code {...attributes}>{children}</code>
            case 'italic':
                return <em {...attributes}>{children}</em>
            case 'underlined':
                return <u {...attributes}>{children}</u>
            default:
                return next()
        }
    }

    /**
     * On change, save the new `value`.
     *
     * @param {Editor} editor
     */

    //onChange = ({ value }) => {
    //    this.setState({ value })
    //}

    onChange = change => {
        const inputValue = getInput(change.value)

        if (inputValue !== this.lastInputValue) {
            this.lastInputValue = inputValue

            if (hasValidAncestors(change.value)) {
                this.search(inputValue)
            }

            const { selection } = change.value

            let annotations = change.value.annotations.filter(
                annotation => annotation.type !== CONTEXT_ANNOTATION_TYPE
            )

            if (inputValue && hasValidAncestors(change.value)) {
                const key = getMentionKey()

                annotations = annotations.set(key, {
                    anchor: {
                        key: selection.start.key,
                        offset: selection.start.offset - inputValue.length,
                    },
                    focus: {
                        key: selection.start.key,
                        offset: selection.start.offset,
                    },
                    type: CONTEXT_ANNOTATION_TYPE,
                    key: getMentionKey(),
                })
            }

            this.setState({ value: change.value }, () => {
                // We need to set annotations after the value flushes into the editor.
                this.editorRef.current.setAnnotations(annotations)
            })
            return
        }

        this.setState({ value: change.value });
    }

    search(searchQuery) {
        // We don't want to show the wrong users for the current search query, so
        // wipe them out.
        this.setState({
            users: [],
        })

        if (!searchQuery) return

        // In order to make this seem like an API call, add a set timeout for some
        // async.
        setTimeout(() => {
            // WARNING: In a production environment you should escape the search query.
            const regex = RegExp(`^${searchQuery}`, 'gi')

            // If you want to get fancy here, you can add some emphasis to the part
            // of the string that matches.
            const result = _.filter(users, user => {
                return user.username.match(regex)
            })

            this.setState({
                // Only return the first 5 results
                users: result.slice(0, 5),
            })
        }, 50)
    }

    /**
     * On key down, if it's a formatting command toggle a mark.
     *
     * @param {Event} event
     * @param {Editor} editor
     * @return {Change}
     */

    onKeyDown = (event, editor, next) => {
        let mark
        
        if (isBoldHotkey(event)) {
            mark = 'bold'
        } else if (isItalicHotkey(event)) {
            mark = 'italic'
        } else if (isUnderlinedHotkey(event)) {
            mark = 'underlined'
        } else if (isCodeHotkey(event)) {
            mark = 'code'
        } else {
            return next()
        }

        event.preventDefault()
        editor.toggleMark(mark)
    }

    /**
     * When a mark button is clicked, toggle the current mark.
     *
     * @param {Event} event
     * @param {String} type
     */

    onClickMark = (event, type) => {
        this.editor = this.editorRef.current;
        event.preventDefault()
        this.editor.toggleMark(type)
    }

    /**
     * When a block button is clicked, toggle the block type.
     *
     * @param {Event} event
     * @param {String} type
     */

    onClickBlock = (event, type) => {
        event.preventDefault()

        const { editor } = this
        const { value } = editor
        const { document } = value

        // Handle everything but list buttons.
        if (type !== 'bulleted-list' && type !== 'numbered-list') {
            const isActive = this.hasBlock(type)
            const isList = this.hasBlock('list-item')

            if (isList) {
                editor
                    .setBlocks(isActive ? DEFAULT_NODE : type)
                    .unwrapBlock('bulleted-list')
                    .unwrapBlock('numbered-list')
            } else {
                editor.setBlocks(isActive ? DEFAULT_NODE : type)
            }
        } else {
            // Handle the extra wrapping required for list buttons.
            const isList = this.hasBlock('list-item')
            const isType = value.blocks.some(block => {
                return !!document.getClosest(block.key, parent => parent.type === type)
            })

            if (isList && isType) {
                editor
                    .setBlocks(DEFAULT_NODE)
                    .unwrapBlock('bulleted-list')
                    .unwrapBlock('numbered-list')
            } else if (isList) {
                editor
                    .unwrapBlock(
                        type === 'bulleted-list' ? 'numbered-list' : 'bulleted-list'
                    )
                    .wrapBlock(type)
            } else {
                editor.setBlocks('list-item').wrapBlock(type)
            }
        }
    }
}

function hasValidAncestors(value) {
    

    // In this simple case, we only want mentions to live inside a paragraph.
    // This check can be adjusted for more complex rich text implementations.
    return true;
}

export default withRouter(RichTextExample);