import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import Globals from '../../config/globals';
import Axios from '../../config/axios';
//import DatePicker from 'react-date-picker';
import DatePicker from 'react-datepicker';
import Moment from 'moment';
import { smallBox, SmartMessageBox } from "../../common/utils/functions";
import TabPanel from './TabPanel';
import { Button, Modal } from 'react-bootstrap';

import "react-datepicker/dist/react-datepicker.css";

const object = {
    name: "",
    value: ""
}

class ProjectDetails extends Component {

    constructor(props) {

        super(props);
        console.log(props);

        this.state = {
            loading: true,
            project: {},
            companies: [],
            companiesFiltered: [],
            projectTypes: [],
            statuses: [],
            projects: [],
            projectResources: [],
            resourceFullNames: [],
            projectGroups: [],
            filteredProjectGroups: [],
            clientContacts: [],
            createdBy: {},
            updatedBy: {},
            projectContact: {},
            company: {},
            itemsChanged: [],
            reload: false,
            activityContact: "",
            activityContactID: 0,
            activityAssignedTo: "",
            activityAssignedToID: 0,
            showCompleteModal: false,
            companyIdList: [],
            formFields: {
                companyName: "",
                projectType: "",
                projectResource: "",
                projectStatus: "",
                projectGroup: "",
                projectName: "",
                projectContact: "",
                contactID: 0,
                dateNeededBy: "",
                projectPriority: "",
                workExclusive: false,
                createdBy: "",
                lastUpdatedBy: "",
                dateCompleted: null,
                projectDetails: "",
                createdFName: "",
                createdLName: "",
                createdDate: new Date(),
                updatedDate: new Date(),
                assignedToFullName: "",
                assignedToId: 0,
                updatedBy: 0,
                confidentialProject: false
            },
            tempDate: new Date()
        };

        this.goBack = this.goBack.bind(this);
        this.getCompanies = this.getCompanies.bind(this);
        this.getActiveStatuses = this.getActiveStatuses.bind(this);
        this.getActiveProjectTypes = this.getActiveProjectTypes.bind(this);
        //this.getProjectList = this.getProjectList.bind(this);
        this.getProjectResources = this.getProjectResources.bind(this);
        this.getProjectGroups = this.getProjectGroups.bind(this);
        this.setStateFromGlobals = this.setStateFromGlobals.bind(this);
        this.getProjectType = this.getProjectType.bind(this);
        this.getCreatedByUser = this.getCreatedByUser.bind(this);
        this.getUpdatedByUser = this.getUpdatedByUser.bind(this);
        this.getProjectContact = this.getProjectContact.bind(this);
        this.handleModalInputChange = this.handleModalInputChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.onCalendarChange = this.onCalendarChange.bind(this);
        this.onDateNeededChange = this.onDateNeededChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getCompanyByName = this.getCompanyByName.bind(this);
        this.updateProject = this.updateProject.bind(this);
        this.markProjectComplete = this.markProjectComplete.bind(this);
        this.getClientContacts = this.getClientContacts.bind(this);
        this.handleNotifications = this.handleNotifications.bind(this);
        this.setFormFields = this.setFormFields.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.getChangedCompanyByName = this.getChangedCompanyByName.bind(this);
        this.updateActivity = this.updateActivity.bind(this);
        this.getCompanyIdList = this.getCompanyIdList.bind(this);
        this.getCompaniesFiltered = this.getCompaniesFiltered.bind(this);

        this.sendActivityUpdate = this.sendActivityUpdate.bind(this);

        this.handleChange = this.handleChange.bind(this);

        this.addActivity = this.addActivity.bind(this);
        this.getFilteredProjectGroups = this.getFilteredProjectGroups.bind(this);

        this.projectDetailsIsDirty = false;
    }

    handleChange(date) {
        this.setState({
            tempDate: date
        });
    }

    componentDidMount() {
        this.setState({
            project: this.props.history.location.state.project
        });


        this.setFormFields();

        this.getCompanies();
        this.getActiveProjectTypes();
        this.getActiveStatuses();
        //this.getProjectList();
        this.getProjectResources();
        this.getProjectGroups();
        this.getProjectType();
        this.getCreatedByUser();
        this.getUpdatedByUser();
        this.getProjectContact();
        this.getCompanyByName();
        this.getCompanyIdList();
        if (Globals.userInfo.userType !== 'Admin') {
            this.getChangedCompanyByName(Globals.userInfo.company_name);
        } else {
            this.getChangedCompanyByName(this.props.location.state.project.companiesModel.name);
        }


    }

    setFormFields() {
        var ffCopy = { ...this.state.formFields };
        console.log(this.props.history.location.state.project);

        ffCopy.companyName = this.props.history.location.state.project.companiesModel.name;
        ffCopy.projectType = this.props.history.location.state.project.projectsModel.projectType;
        ffCopy.assignedToFullName = this.props.history.location.state.project.assignedToFullName;
        ffCopy.assignedToId = this.props.history.location.state.project.projectsModel.assignedTo;
        ffCopy.projectStatus = this.props.history.location.state.project.statusesModel.status;
        ffCopy.projectGroup = this.props.history.location.state.project.projectsModel.projectGroup;
        ffCopy.projectName = this.props.history.location.state.project.projectsModel.projectName;
        ffCopy.projectContact = this.props.history.location.state.project.projectsModel.contactPerson;
        ffCopy.contactID = this.props.history.location.state.project.projectsModel.projectContact;
        ffCopy.usersNotified = this.props.history.location.state.project.projectsModel.usersNotified;

        var d = new Date(this.props.history.location.state.project.projectsModel.dateNeededBy);
        d.setMinutes(d.getMinutes() + d.getTimezoneOffset());
        ffCopy.dateNeededBy = d;

        ffCopy.projectPriority = this.props.history.location.state.project.projectsModel.priority;
        ffCopy.workExclusive = this.props.history.location.state.project.projectsModel.workExclusive;
        ffCopy.confidentialProject = this.props.history.location.state.project.projectsModel.confidentialProject;

        if (this.props.history.location.state.project.projectsModel.dateCompleted) {
            var d2 = new Date(this.props.history.location.state.project.projectsModel.dateCompleted);
            d2.setMinutes(d2.getMinutes() + d2.getTimezoneOffset());
            ffCopy.dateCompleted = d2;
        }
        else {
            ffCopy.dateCompleted = null;
        }



        ffCopy.projectDetails = this.props.history.location.state.project.projectsModel.projectDetails;
        ffCopy.createdDate = this.props.history.location.state.project.projectsModel.createdOn;
        ffCopy.updatedDate = this.props.history.location.state.project.projectsModel.updatedOn;

        this.setState({
            formFields: ffCopy
        });
    }

    onCalendarChange = (date) => {
        var ffCopy = { ...this.state.formFields }
        ffCopy.dateCompleted = date;

        let list = this.state.itemsChanged;
        const obj = Object.create(object);
        var myVar = false;
        var spot;

        var d = Moment(date).format('L')

        for (var i = 0; i < list.length; i++) {
            if (list[i].name === 'dateCompleted') {
                myVar = true;
                spot = i;
            }
        }

        if (myVar === true) {
            console.log(list[spot].name);

            list[spot].value = d;
        }
        else {

            obj.name = 'dateCompleted';
            obj.value = d;
            list.push(obj);
        }

        this.setState({ formFields: ffCopy, itemsChanged: list },
            () => {
                if (JSON.stringify(this.state.formFields) !== JSON.stringify(this.formFields)) {
                    this.projectDetailsIsDirty = true;  // dirty
                } else {
                    this.projectDetailsIsDirty = false; // pristine
                }
            });


    }

    onDateNeededChange = (date2) => {
        var ffCopy = { ...this.state.formFields };
        ffCopy.dateNeededBy = date2

        let list = this.state.itemsChanged;
        const obj = Object.create(object);
        var myVar = false;
        var spot;

        var d = Moment(date2).format('L')

        for (var i = 0; i < list.length; i++) {
            if (list[i].name === 'dateNeededBy') {
                myVar = true;
                spot = i;
            }
        }

        if (myVar === true) {
            console.log(list[spot].name);

            list[spot].value = d;
        }
        else {

            obj.name = 'dateNeededBy';
            obj.value = d;
            list.push(obj);
        }

        this.setState({ formFields: ffCopy, itemsChanged: list },
            () => {
                if (JSON.stringify(this.state.formFields) !== JSON.stringify(this.formFields)) {
                    this.projectDetailsIsDirty = true;  // dirty
                } else {
                    this.projectDetailsIsDirty = false; // pristine
                }
            });
    }

    getCompanyIdList() {
        let postdata = {};
        postdata.companies_id = Globals.userInfo.users_Id;
        console.log(Globals.userInfo);
        Axios.post(`/api/GetCompanyIdList`, postdata
        ).then(response => {
            this.setState({
                companiesFiltered: response.data
            });
        }).catch(error => {
            console.log(error);
        });
    }

    getCreatedByUser() {
        let postdata = {};
        postdata.companies_id = this.props.history.location.state.project.projectsModel.createdBy;

        Axios.post(`/api/GetUserById`, postdata
        ).then(response => {
            this.setState({
                createdBy: response.data,
                formFields: { ...this.state.formFields, createdBy: response.data.fName + " " + response.data.lName }
            });
        }).catch(error => {
            this.setState({
                createdBy: null
            });
        });
    }

    getUpdatedByUser() {
        let postdata = {};
        postdata.companies_id = this.props.history.location.state.project.projectsModel.updatedBy;
        if (postdata.companies_id !== null) {
            Axios.post(`/api/GetUserById`, postdata
            ).then(response => {
                this.setState({
                    updatedBy: response.data,
                    formFields: { ...this.state.formFields, lastUpdatedBy: response.data.fName + " " + response.data.lName }
                });
            }).catch(error => {
                this.setState({
                    updatedBy: null
                })
            });
        }

    }

    getClientContacts(id) {
        let postdata = {};
        postdata.companies_id = id;

        Axios.post(`/api/GetClientContacts`, postdata
        ).then(response => {
            let clientContacts = response.data;
            console.log(this.props.history.location.state.project.projectsModel.usersNotified);
            if (this.props.history.location.state.project.projectsModel.usersNotified) {
                let usersNotified = this.props.history.location.state.project.projectsModel.usersNotified.split('|');

                for (var i = 0; i < usersNotified.length - 1; i++) {
                    const index = clientContacts.findIndex(contact => contact.users_Id === parseInt(usersNotified[i]));

                    console.log(index);
                    clientContacts[index].tempIsSelected = true;
                }

            }
            //console.log(response.data);
            this.setState({ clientContacts }, () => console.log(this.state.clientContacts));

        }).catch(error => {
            console.log(error);
        });
    }

    handleNotifications(event) {
        event.preventDefault();

        const target = event.target;
        var value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        console.log(value);
        console.log(this.state.clientContacts);

        var curCont = JSON.parse(value);
        curCont.tempIsSelected = curCont.tempIsSelected ? !curCont.tempIsSelected : true;
        console.log(curCont);

        const index = this.state.clientContacts.findIndex(contact => contact.users_Id === curCont.users_Id),
            clientContacts = [...this.state.clientContacts] // important to create a copy, otherwise you'll modify state outside of setState call
        clientContacts[index] = curCont;
        this.setState({ clientContacts }, () => { console.log(this.state.clientContacts); this.projectDetailsIsDirty = true; });

        let changedValue = "";
        for (var i = 0; i < clientContacts.length; i++) {
            if (clientContacts[i].tempIsSelected) {
                changedValue += clientContacts[i].fName + " " + clientContacts[i].lName + ", ";
            }
        }

        let list = this.state.itemsChanged;
        const obj = {
            name: 'usersNotified',
            value: changedValue
        }
        list.push(obj);

        this.setState({
            itemsChanged: list
        }, () => console.log(this.state.itemsChanged));

    }

    getProjectContact() {
        let postdata = {};
        postdata.companies_id = this.props.history.location.state.project.projectsModel.projectContact;
        Axios.post(`/api/GetUserById`, postdata
        ).then(response => {
            this.setState({
                projectContact: response.data
            }, () => {
                this.getClientContacts(this.props.history.location.state.project.projectsModel.companies_Id);
            });
        }).catch(error => {
            this.getClientContacts(this.props.history.location.state.project.projectsModel.companies_Id);
            console.log(error);
        });
    }

    //getProjectList() {
    //    if (Globals.userInfo.userType === 'Admin') {
    //        Axios.post(`/api/GetProjectListNoCriteria`
    //        ).then(response => {

    //            this.setState({
    //                projects: response.data,
    //                loading: false
    //            });
    //        }).catch(error => {
    //            console.log(error);
    //        });
    //    }
    //    else {
    //        let postdata = {};
    //        postdata.companies_id = Globals.userInfo.companies_id;

    //        Axios.post(`/api/GetProjectListWithCompany`, postdata
    //        ).then(response => {

    //            this.setState({
    //                projects: response.data,
    //                loading: false
    //            });
    //        }).catch(error => {
    //            console.log(error);
    //        });
    //    }
    //}

    getProjectType() {
        let postdata = {};
        postdata.companies_id = this.props.history.location.state.project.projectsModel.projectTypes_Id;

        Axios.post(`/api/GetProjectTypeByID`, postdata
        ).then(response => {
            Globals.projectData.projectType = response.data.projectType;
        }).catch(error => {
            console.log(error);
        });
    }

    setStateFromGlobals() {
        var test = this.props.history.location.state.project;
        this.setState({
            test: test.priority
        });
    }

    getCompanies() {
        Axios.post(`/api/GetCompaniesList`
        ).then(response => {

            this.setState({
                companies: response.data
            }, () => this.getCompanyIdList());
        }).catch(error => {
            console.log(error);
        });
    }

    getCompaniesFiltered(companies, companyIdList) {
        let tempList = [];
        for (var i = 0; i < companyIdList.length; i++) {
            let postdata = {};
            postdata.companies_id = companyIdList[i];
            Axios.post(`/api/GetCompanyById`, postdata
            ).then(response => {
                tempList.push(response.data);
            }).catch(error => {
                console.log(error);
            });
        }



        this.setState({
            companiesFiltered: tempList
        }, () => console.log(this.state.companiesFiltered));
    }

    getActiveProjectTypes() {
        Axios.post(`/api/GetActiveProjectTypes`
        ).then(response => {
            this.setState({
                projectTypes: response.data
            });
        }).catch(error => {
            console.log(error);
        });
    }

    getActiveStatuses() {
        Axios.post(`/api/GetActiveStatuses`
        ).then(response => {
            this.setState({
                statuses: response.data
            });

        }).catch(error => {
            console.log(error);
        });
    }

    getProjectResources() {

        var tempList = [];
        Axios.post(`/api/GetProjectResourcesAsAdmin`
        ).then(response => {
            for (var i = 0; i < response.data.length; i++) {
                var name = response.data[i].fName + " " + response.data[i].lName;
                tempList.push(name);
            }

            this.setState({
                projectResources: response.data,
                resourceFullNames: tempList
            });

        }).catch(error => {
            console.log(error);
        });
    }

    getProjectGroups() {
        Axios.post(`/api/GetProjectGroupsAsAdmin`
        ).then(response => {
            this.setState({
                projectGroups: response.data
            });

        }).catch(error => {
            console.log(error);
        });
    }

    getFilteredProjectGroups(id) {
        let postdata = {};
        postdata.companies_id = id;
        Axios.post(`/api/GetProjectGroupsAsClient`, postdata
        ).then(response => {
            this.setState({

                filteredProjectGroups: response.data
            });
        }).catch(error => {
            console.log(error);
        });
    }

    getCompanyByName() {
        let postdata = {};
        postdata.company_name = this.state.formFields.companyName;

        Axios.post(`/api/GetCompanyByName`, postdata
        ).then(response => {
            this.setState({
                company: response.data
            });
        }).catch(error => {
            console.log(error);
        });
    }

    getChangedCompanyByName(name) {
        let postdata = {};
        postdata.company_name = name;

        Axios.post(`/api/GetCompanyByName`, postdata
        ).then(response => {
            this.setState({
                company: response.data
            }, () => { this.getClientContacts(this.state.company.companies_Id); this.getFilteredProjectGroups(this.state.company.companies_Id); });
        }).catch(error => {
            console.log(error);
        });
    }

    goBack() {
        if (this.projectDetailsIsDirty) {

            SmartMessageBox(
                {
                    title: "All changes will be lost.",
                    content:
                        "Are you sure you would like to proceed?",
                    buttons: "[No][Yes]"
                },
                (ButtonPressed) => {
                    if (ButtonPressed === "Yes") {
                        let path = '/your-projects';
                        console.log(this.props.history);
                        this.props.history.push({
                            pathname: path,
                            state: { searchCriteria: this.props.location.state.searchCriteria }
                        });
                    }
                    if (ButtonPressed === "No") {
                        return 0;
                    }
                }
            );
        }
        else {
            let path = '/your-projects';
            this.props.history.push({
                pathname: path,
                state: { searchCriteria: this.props.location.state.searchCriteria }
            });
        }

    }

    deleteProject(id) {
        let postdata = {};
        postdata.companies_id = id;

        SmartMessageBox(
            {
                title: "You are about to delete this project.",
                content:
                    "Are you sure you would like to proceed?",
                buttons: "[No][Yes]"
            },
            (ButtonPressed) => {
                if (ButtonPressed === "Yes") {
                    Axios.post(`/api/DeleteProject`, postdata
                    ).then(response => {
                        this.props.history.push({
                            pathname: '/your-projects',
                            state: { projectDeleted: true }
                        });
                    }).catch(error => {
                        console.log(error);
                    });
                }
                if (ButtonPressed === "No") {
                    return 0;
                }
            }
        );


    }

    handleClick(event) {
        event.preventDefault();

        switch (event.target.id) {
            case 'projectDetailsCancelBtn':
                if (this.projectDetailsIsDirty) {

                    SmartMessageBox(
                        {
                            title: "You are about to lose your changes.",
                            content:
                                "Are you sure you would like to proceed?",
                            buttons: "[No][Yes]"
                        },
                        (ButtonPressed) => {
                            if (ButtonPressed === "Yes") {
                                this.projectDetailsIsDirty = false;
                                this.setFormFields();
                            }
                            if (ButtonPressed === "No") {
                                return 0;
                            }
                        }
                    );

                }

                break;
            case 'projectDetailsDeleteBtn':
                this.deleteProject(this.state.project.projectsModel.projects_Id);
                break;
            case 'projectDetailsCompleteBtn':
                this.showModal(this.state.project);
                break;
            case 'closeModalLink':
                this.setState({
                    showCompleteModal: false
                });
                break;
            case 'completeProjectModalBtn':
                this.markProjectComplete();
                break;
            default:
                alert('default condition hit');
                break;
        }
    }

    markProjectComplete() {
        let postdata = {
            project: {},
            statuses: {}
        };

        postdata.project = this.state.project.projectsModel;
        postdata.statuses = this.state.project.statusesModel;
        postdata.project.statuses_Id = 3;
        postdata.project.dateCompleted = new Date();

        let list = this.state.itemsChanged;
        const obj = {
            name: 'completedNotes',
            value: this.state.project.projectsModel.completedNotes
        }
        const obj2 = {
            name: 'dateCompleted',
            value: postdata.project.dateCompleted
        }
        list.push(obj);
        list.push(obj2);

        this.setState({
            itemsChanged: list
        }, () => console.log(this.state.itemsChanged));

        Axios.post(`/api/UpdateProject`, postdata
        ).then(response => {
            this.addActivity();
            this.setState({
                project: postdata.project,
                showCompleteModal: false
            });

            this.props.history.push({
                pathname: '/your-projects',
                state: { projectCompleted: true }
            });
        }).catch(error => {
            console.log(error);
        });
    }

    handleModalInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        let tempProject = this.state.project;
        tempProject.projectsModel.completedNotes = value;


        this.setState({
            project: tempProject
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (name === 'companyName') {
            this.getChangedCompanyByName(value);
        };

        let list = this.state.itemsChanged;
        const obj = Object.create(object);
        var myVar = false;
        var spot;
        for (var i = 0; i < list.length; i++) {
            if (list[i].name === name) {
                myVar = true;
                spot = i;
            }
        }

        if (myVar === true) {
            list[spot].value = value;
        }
        else {

            obj.name = name;
            obj.value = value;
            list.push(obj);
        }

        this.setState({ itemsChanged: list }, () => console.log(this.state.itemsChanged));

        this.setState({ formFields: { ...this.state.formFields, [name]: value } },
            () => {
                console.log(this.state.formFields);
                if (JSON.stringify(this.state.formFields) !== JSON.stringify(this.formFields)) {
                    this.projectDetailsIsDirty = true;  // dirty
                } else {
                    this.projectDetailsIsDirty = false; // pristine
                }
            });
    }

    handleSubmit() {
        let postdata2 = {};
        postdata2.companies_id = this.state.formFields.contactID;
        Axios.post(`/api/GetUserById`, postdata2
        ).then(response => {
            this.setState({
                activityContact: response.data.fName + " " + response.data.lName
            });
        }).catch(error => {
            console.log(error);
        });

        let postdata3 = {};
        postdata3.companies_id = this.state.formFields.assignedToId;
        Axios.post(`/api/GetUserById`, postdata3
        ).then(response => {
            this.setState({
                activityAssignedTo: response.data.fName + " " + response.data.lName
            });
        }).catch(error => {
            console.log(error);
        });

        let postdata = {};
        postdata.company_name = this.state.formFields.companyName;

        Axios.post(`/api/GetCompanyByName`, postdata
        ).then(response => {
            this.setState({
                company: response.data
            }, () => {
                this.updateProject();
            });
        }).catch(error => {
            console.log(error);
        });
    }

    updateProject() {
        let postdata = {
            project: {
                projects_Id: 0,
                companies_Id: 0
            },
            statuses: {}
        };

        postdata.project.projects_Id = this.props.history.location.state.project.projectsModel.projects_Id;
        postdata.project.companies_Id = this.state.company.companies_Id;
        postdata.project.projectName = this.state.formFields.projectName;
        postdata.project.projectType = this.state.formFields.projectType;
        postdata.project.projectStatus = this.state.formFields.projectStatus;
        postdata.project.projectGroup = this.state.formFields.projectGroup;
        postdata.project.priority = this.state.formFields.projectPriority;
        postdata.project.workExclusive = this.state.formFields.workExclusive === true ? 1 : 0;
        postdata.project.projectDetails = this.state.formFields.projectDetails;
        postdata.project.dateNeededBy = this.state.formFields.dateNeededBy;
        postdata.project.projectContact = this.state.formFields.contactID;
        postdata.project.assignedTo = this.state.formFields.assignedToId;
        postdata.project.updatedBy = Globals.userInfo.users_Id;
        postdata.project.confidentialProject = this.state.formFields.confidentialProject === true ? 1 : 0;

        let usersNotified = "";
        console.log(this.state.clientContacts);
        for (var i = 0; i < this.state.clientContacts.length; i++) {
            if (this.state.clientContacts[i].tempIsSelected) {
                usersNotified += this.state.clientContacts[i].users_Id + "|";
            }
        }
        console.log(usersNotified);
        postdata.project.usersNotified = usersNotified;

        postdata.statuses.Status = this.state.formFields.projectStatus;
        console.log(postdata);

        if (!this.projectDetailsIsDirty) {
            smallBox({
                title: "Notice",
                content: "<i class='fa fa-clock-o'></i> <i>No Change</i>",
                color: "red",
                iconSmall: "fa fa-check fa-2x fadeInRight animated",
                timeout: 4000
            });
        }

        else {
            Axios.post(`/api/UpdateProject`, postdata
            ).then(response => {
                console.log("Here");
                if (response.data > 0) {
                    smallBox({
                        title: "Notice",
                        content: "<i class='fa fa-clock-o'></i> <i>Project details have been saved</i>",
                        color: "#659265",
                        iconSmall: "fa fa-check fa-2x fadeInRight animated",
                        timeout: 4000
                    });
                    this.projectDetailsIsDirty = false;

                    this.addActivity();

                }
                else {
                    console.log("Didn't update");
                    smallBox({
                        title: "Notice",
                        content: "<i class='fa fa-clock-o'></i> <i>Did not update</i>",
                        color: "red",
                        iconSmall: "fa fa-check fa-2x fadeInRight animated",
                        timeout: 4000
                    });
                }

            }).catch(error => {
                console.log(error);
            });
        }


    }

    updateActivity() {

        let list = this.state.itemsChanged;
        let activityString = "";

        list.forEach((item) => {
            var string = "";

            switch (item.name) {
                case 'companyName':
                    string = "<li>Company Name has been updated to '" + item.value + "'</li>\n";
                    break;
                case 'projectType':
                    string = "<li>Project Type has been updated to '" + item.value + "'</li>\n";
                    break;
                case 'assignedToId':
                    string = "<li>Assigned To has been updated to '" + this.state.activityAssignedTo + "'</li>\n";
                    break;
                case 'projectStatus':
                    string = "<li>Project Status has been updated to '" + item.value + "'</li>\n";
                    break;
                case 'projectGroup':
                    string = "<li>Project Group has been updated to '" + item.value + "'</li>\n";
                    break;
                case 'projectName':
                    string = "<li>Project Name has been updated to '" + item.value + "'</li>\n";
                    break;
                case 'contactID':
                    string = "<li>Client Contact has been updated to '" + this.state.activityContact + "'</li>\n";
                    break;
                case 'projectPriority':
                    string = "<li>Project Priority has been updated to '" + item.value + "'</li>\n";
                    break;
                case 'workExclusive':
                    string = "<li>Urgent Request has been updated to " + item.value + "</li>\n";
                    break;
                case 'confidentialProject':
                    string = "<li>Confidential Project has been updated to " + item.value + "</li>\n";
                    break;
                case 'projectDetails':
                    string = "<li>Project Details has been updated to '" + item.value + "'</li>\n";
                    break;
                case 'dateNeededBy':
                    string = "<li>Date Needed By has been updated to '" + item.value + "'</li>\n";
                    break;
                case 'dateCompleted':
                    string = "<li><p>Project has been completed on <em>" + Moment(item.value).format('L') + " at " + Moment(item.value).format('LT') +
                        "</em></p> <p>Completion Notes: <strong>" + this.state.project.projectsModel.completedNotes + "</strong></p></li >\n";
                    break;
                case 'usersNotified':
                    string = "<li>The users who will be notified have been updated to: " + item.value + "</li>\n";
                    break;
                default:
                    break;
            }

            activityString += string;

        });
        return activityString;
    }

    addActivity() {
        var activityString = this.updateActivity();

        let postdata = {
            activity: {}
        };
        postdata.activity.inputID = Globals.userInfo.users_Id;
        postdata.activity.inputName = Globals.userInfo.fName + " " + Globals.userInfo.lName;
        postdata.activity.createdDate = new Date();
        postdata.activity.activity = "<p>" + activityString + "</p>";
        postdata.activity.projectsID = this.props.history.location.state.project.projectsModel.projects_Id;

        Axios.post(`/api/AddActivity`, postdata
        ).then(response => {
            this.setState({
                itemsChanged: [],
                reload: !this.state.reload
            });
            this.sendActivityUpdate(postdata.activity.activity, postdata.activity);
        }).catch(error => {
            console.log(error);
        });
    }

    sendActivityUpdate(content, activity) {
        let postdata = {
            project: {},
            activity: {}
        };
        postdata.project = this.props.history.location.state.project.projectsModel;
        postdata.project.assignedTo = this.state.formFields.assignedToId;
        postdata.project.projectContact = this.state.formFields.contactID;
        postdata.activity = activity;
        postdata.activity.inputName = Globals.userInfo.fName + " " + Globals.userInfo.lName;
        postdata.activity.createdDate = new Date();
        postdata.activity.activity = content;
        postdata.suppressAllEmails = Globals.userInfo.suppressAllEmails;

        let usersNotified = "";
        for (var i = 0; i < this.state.clientContacts.length; i++) {
            if (this.state.clientContacts[i].tempIsSelected) {
                usersNotified += this.state.clientContacts[i].users_Id + "|";
            }
        }

        postdata.project.usersNotified = usersNotified;
        Axios.post(`/api/SendActivityUpdate`, postdata
        ).catch(error => {
            console.log(error);
        });
    }

    showModal(project) {
        console.log(project);
        this.setState({ showCompleteModal: true });
    }

    render() {
        var isNotAdmin = Globals.userInfo.userType === 'Admin' ? false : true;
        return (

            <div>
                <div className="adminSearchDiv">
                    <div className="row">
                        <div className="col-lg-6 scrollableDiv">
                            <div className="col-12 paddingBottom25">
                                <a onClick={this.goBack}>Return to Project List</a>

                            </div>
                            <div className="row">
                                <div className="col-lg-12 centerAlign"><span className="projectTitle">{this.state.formFields.projectName}</span></div>
                                <div className="col-lg-6 col-sm-12 col-xs-12">
                                    <dt><label>Company</label></dt>
                                    <dd>
                                        {this.state.companiesFiltered.length < 2 ? (
                                            <div>
                                                {Globals.userInfo.userType !== "Admin" ? (
                                                    <input className="form-control" name="projectName" value={this.state.formFields.companyName} readOnly />
                                                ) : (
                                                        <select className="form-control" id="example-select" name="companyName" value={this.state.formFields.companyName} onChange={this.handleInputChange}>
                                                            {this.state.companies.map((company, idx) => <option key={idx} value={company.name} >{company.name}</option>)}
                                                        </select>
                                                    )}

                                            </div>
                                        ) : (
                                                <select className="form-control" id="example-select" name="companyName" value={this.state.formFields.companyName} onChange={this.handleInputChange}>
                                                    {this.state.companiesFiltered.map((company, idx) => <option key={idx} value={company.name} >{company.name}</option>)}
                                                </select>)}

                                    </dd>
                                </div>
                                <div className="col-lg-6 col-sm-12 col-xs-12">
                                    <dt><label>Type of Project</label></dt>
                                    <dd>
                                        <select className="form-control" id="example-select" name="projectType" value={this.state.formFields.projectType} onChange={this.handleInputChange}>
                                            <option value=""></option>
                                            {this.state.projectTypes.map((type) => <option key={type.projectType} value={type.projectType}>{type.projectType}</option>)}
                                        </select>
                                    </dd>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-sm-12 col-xs-12">
                                    <dt><label>Assigned To</label></dt>
                                    {Globals.userInfo.userType === 'Admin' ? (
                                        <select className="form-control" id="example-select" name="assignedToId" value={this.state.formFields.assignedToId} onChange={this.handleInputChange}>
                                            <option value=""></option>
                                            {this.state.projectResources.map((resource) => <option key={resource.users_Id} value={resource.users_Id}>{resource.fName} {resource.lName}</option>)}
                                        </select>
                                    ) : (
                                            <input className="form-control" value={this.state.formFields.assignedToFullName} readOnly />
                                        )}

                                </div>
                                <div className="col-lg-6 col-sm-12 col-xs-12">
                                    <dt><label>Project Status</label></dt>

                                    <select className="form-control" id="example-select" name="projectStatus" value={this.state.formFields.projectStatus} onChange={this.handleInputChange}>
                                        {this.state.statuses.map((status) => <option key={status.status} value={status.status} >{status.status}</option>)}
                                    </select>
                                </div>
                            </div>
                            <div className="row">
                                {/*<div className="col-lg-6 col-sm-12 col-xs-12">
                                        <dt><label>Project Group</label></dt>
                                        <dd>
                                            <select className="form-control" id="example-select" name="projectGroup" value={this.state.formFields.projectGroup ? this.state.formFields.projectGroup : ''} onChange={this.handleInputChange}>
                                                <option value=""></option>
                                                {this.state.filteredProjectGroups.map((group) => <option key={group} value={group}>{group}</option>)}
                                            </select>

                                        </dd>
                                    </div>*/}
                                <div className="col-lg-6 col-sm-12 col-xs-12">
                                    <dt><label>Project Priority</label></dt>
                                    <dd>

                                        <select className="form-control" id="example-select" name="projectPriority" value={this.state.formFields.projectPriority ? this.state.formFields.projectPriority : ""} onChange={this.handleInputChange}>
                                            <option value=""></option>
                                            <option value="Low">Low</option>
                                            <option value="Medium">Medium</option>
                                            <option value="High">High</option>
                                        </select>
                                    </dd>
                                </div>

                                <div className="col-lg-6 col-sm-12 col-xs-12">
                                    <dt><label>Project Name</label></dt>
                                    <dd>
                                        <input className="form-control" name="projectName" value={this.state.formFields.projectName} onChange={this.handleInputChange} />
                                    </dd>
                                </div>


                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-sm-12 col-xs-12">
                                    <dt><label>Client Contact</label></dt>
                                    <dd>

                                        <select className="form-control" id="example-select" name="contactID" value={this.state.formFields.contactID} onChange={this.handleInputChange}>
                                            {/*NEEDS FIXING*/}
                                            <option value=""></option>
                                            {this.state.clientContacts.map((contact) => <option key={contact.users_Id} value={contact.users_Id}>{contact.fName} {contact.lName}</option>)}
                                        </select>
                                    </dd>
                                </div>
                                <div className="col-lg-6 col-sm-12 col-xs-12">
                                    <dt><label>Date Needed By</label></dt>
                                    <DatePicker selected={this.state.formFields.dateNeededBy} onChange={this.onDateNeededChange}
                                        className={"form-control"} />
                                </div>
                            </div>
                            <div className="row">





                                <div className="col-lg-6 col-sm-12 col-xs-12">
                                    <dt> <label>Urgent Request?</label> <input type="checkbox" name="workExclusive" checked={this.state.formFields.workExclusive} onChange={this.handleInputChange} /> </dt>
                                    <dt className="marginTop10px"> <label>Confidential Project?</label> <input type="checkbox" name="confidentialProject" checked={this.state.formFields.confidentialProject} onChange={this.handleInputChange} /> </dt>
                                </div>
                                <div className="col-lg-6 col-sm-12 col-xs-12">
                                    <dt><label>Last Updated By</label></dt>
                                    <dd>
                                        <input className="form-control" value={this.state.updatedBy.users_Id !== 0 ? this.state.formFields.lastUpdatedBy + " on " + Moment(this.state.formFields.updatedDate).format('L') : "--"} readOnly />
                                    </dd>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-sm-12 col-xs-12">
                                    <dt><label>Created By</label></dt>
                                    <dd>
                                        <input className="form-control" value={this.state.formFields.createdBy + " on " + Moment(this.state.formFields.createdDate).format('L')} readOnly />
                                    </dd>
                                </div>
                                <div className="col-lg-6 col-sm-12 col-xs-12">
                                    <dt><label>Date Completed</label></dt>
                                    <DatePicker selected={this.state.formFields.dateCompleted} onChange={this.onCalendarChange}
                                        className={"form-control"} readOnly />
                                </div>


                            </div>
                            <div className="col-lg-4 col-sm-6 col-xs-12">
                                <label className="textarea">Who gets notified?</label>
                                <div className="listCategoriesContainer">
                                    {this.state.clientContacts.map((contact) => <option key={contact.users_Id} value={JSON.stringify(contact)} className={"listCategoriesBtn " + (contact.tempIsSelected && "activeListCategories")} onClick={this.handleNotifications}>{contact.fName} {contact.lName}</option>)}
                                </div>
                            </div>
                            <div className="col-lg-12 col-sm-12 col-xs-12">
                                <dt><label>Project Details</label></dt>
                                <dd>
                                    <textarea className="form-control" rows="6" name="projectDetails" placeholder="Description" value={this.state.formFields.projectDetails} onChange={this.handleInputChange} />
                                </dd>
                            </div>
                            <div className="row">

                                <div className="col-lg-10">
                                    <div className="row justify-content-end">
                                        <div className="col-lg-6 col-xs-12">
                                            <button className="btn projectDetailsSubmitBtn" onClick={this.handleSubmit}>Save Changes</button>
                                        </div>
                                        <div className="col-lg-6 col-xs-12">
                                            <button className="btn projectDetailsSubmitBtn" id="projectDetailsCancelBtn" onClick={this.handleClick}>Cancel Changes</button>
                                        </div>
                                        <div className="col-lg-6 col-xs-12">
                                            <button className="btn projectDetailsSubmitBtn" id="projectDetailsCompleteBtn" onClick={this.handleClick}>Mark Project Complete</button>
                                        </div>
                                        <div className="col-lg-6 col-xs-12">
                                            <a className="floatRight margin15px" onClick={this.goBack}>Return to Project List</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    <i className="fas fa-trash-alt floatRight margin15px" onClick={this.handleClick} id="projectDetailsDeleteBtn"></i>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 scrollableDiv">
                            <TabPanel projectID={this.props.history.location.state.project.projectsModel.projects_Id} project={this.props.history.location.state.project} reload={this.state.reload} />
                        </div>
                    </div>
                </div>

                <Modal size='md' show={this.state.showCompleteModal} onHide={(param) => this.showCompleteModal(param)} backdrop='static'>
                    <Modal.Header>
                        Add File Description
                        <a className="alignRight" id="closeModalLink" onClick={this.handleClick}>&times;</a>
                    </Modal.Header>
                    <Modal.Body>
                        <section className="col col-xs-12">

                            <label className="control-label" htmlFor="majcatselect">Add any project completion notes:</label>
                            <textarea style={{ width: '100%' }} rows="4" name="completedNotes" placeholder="Project Completion Notes" onChange={this.handleModalInputChange} />

                        </section>
                    </Modal.Body>
                    <Modal.Footer>
                        <a id="closeModalLink" onClick={this.handleClick}>Cancel</a>
                        <Button id="completeProjectModalBtn" className="btn projectDetailsSubmitBtn" onClick={this.handleClick}>Mark Project Complete</Button>
                    </Modal.Footer>
                </Modal>

            </div>
        );
    }
}

export default withRouter(ProjectDetails);
